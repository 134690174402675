import React, { useState, useEffect, useRef } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../../../hooks/useLoginCheck";

import { ReactComponent as SeatSection1Svg } from "../../../../assets/recital2025/seatSection1.svg";
import { ReactComponent as SeatSection2Svg } from "../../../../assets/recital2025/seatSection2.svg";
import { ReactComponent as SeatSection3Svg } from "../../../../assets/recital2025/seatSection3.svg";
import "../../../../styles/scss/recital2025Seat.scss";

import {
  SECTION1_SEATS,
  SECTION2_SEATS,
  SECTION3_SEATS,
} from "../../../../constants/recital2025/seatConstants.mjs";

function SeatPage() {
  useLoginCheck();

  const seat_section_1 = [
    "s1-y-1-10",
    "s1-y-1-11",
    "s1-y-1-12",
    "s1-y-1-13",
    "s1-y-1-14",
    "s1-y-1-15",
    "s1-y-1-16",
    "s1-y-1-17",
    "s1-y-1-19",
    "s1-y-1-20",
    "s1-y-1-21",
    "s1-y-1-22",
    "s1-y-1-23",
    "s1-y-1-24",
    "s1-y-1-25",
    "s1-y-1-26",
    "s1-y-1-27",
    "s1-y-1-28",
    "s1-y-1-29",
    "s1-y-1-30",
    "s1-y-1-32",
    "s1-y-1-33",
    "s1-y-1-34",
    "s1-y-1-35",
    "s1-y-1-36",
    "s1-y-1-37",
    "s1-y-1-38",
    "s1-y-1-39",
    "s1-y-2-9",
    "s1-y-2-10",
    "s1-y-2-11",
    "s1-y-2-12",
    "s1-y-2-13",
    "s1-y-2-14",
    "s1-y-2-15",
    "s1-y-2-16",
    "s1-y-2-17",
    "s1-y-2-19",
    "s1-y-2-20",
    "s1-y-2-21",
    "s1-y-2-22",
    "s1-y-2-23",
    "s1-y-2-24",
    "s1-y-2-25",
    "s1-y-2-26",
    "s1-y-2-27",
    "s1-y-2-28",
    "s1-y-2-29",
    "s1-y-2-30",
    "s1-y-2-32",
    "s1-y-2-33",
    "s1-y-2-34",
    "s1-y-2-35",
    "s1-y-2-36",
    "s1-y-2-37",
    "s1-y-2-38",
    "s1-y-2-39",
    "s1-y-2-40",
    "s1-y-3-8",
    "s1-y-3-9",
    "s1-y-3-10",
    "s1-y-3-11",
    "s1-y-3-12",
    "s1-y-3-13",
    "s1-y-3-14",
    "s1-y-3-15",
    "s1-y-3-16",
    "s1-y-3-17",
    "s1-y-3-19",
    "s1-y-3-20",
    "s1-y-3-21",
    "s1-y-3-22",
    "s1-y-3-23",
    "s1-y-3-24",
    "s1-y-3-25",
    "s1-y-3-26",
    "s1-y-3-27",
    "s1-y-3-28",
    "s1-y-3-29",
    "s1-y-3-30",
    "s1-y-3-32",
    "s1-y-3-33",
    "s1-y-3-34",
    "s1-y-3-35",
    "s1-y-3-36",
    "s1-y-3-37",
    "s1-y-3-38",
    "s1-y-3-39",
    "s1-y-3-40",
    "s1-y-3-41",
    "s1-y-4-8",
    "s1-y-4-9",
    "s1-y-4-10",
    "s1-y-4-11",
    "s1-y-4-12",
    "s1-y-4-13",
    "s1-y-4-14",
    "s1-y-4-15",
    "s1-y-4-16",
    "s1-y-4-17",
    "s1-y-4-19",
    "s1-y-4-20",
    "s1-y-4-21",
    "s1-y-4-22",
    "s1-y-4-23",
    "s1-y-4-24",
    "s1-y-4-25",
    "s1-y-4-26",
    "s1-y-4-27",
    "s1-y-4-28",
    "s1-y-4-29",
    "s1-y-4-30",
    "s1-y-4-32",
    "s1-y-4-33",
    "s1-y-4-34",
    "s1-y-4-35",
    "s1-y-4-36",
    "s1-y-4-37",
    "s1-y-4-38",
    "s1-y-4-39",
    "s1-y-4-40",
    "s1-y-4-41",
    "s1-y-5-7",
    "s1-y-5-8",
    "s1-y-5-9",
    "s1-y-5-10",
    "s1-y-5-11",
    "s1-y-5-12",
    "s1-y-5-13",
    "s1-y-5-14",
    "s1-y-5-15",
    "s1-y-5-16",
    "s1-y-5-17",
    "s1-y-5-19",
    "s1-y-5-20",
    "s1-y-5-21",
    "s1-y-5-22",
    "s1-y-5-23",
    "s1-y-5-24",
    "s1-y-5-25",
    "s1-y-5-26",
    "s1-y-5-27",
    "s1-y-5-28",
    "s1-y-5-29",
    "s1-y-5-30",
    "s1-y-5-32",
    "s1-y-5-33",
    "s1-y-5-34",
    "s1-y-5-35",
    "s1-y-5-36",
    "s1-y-5-37",
    "s1-y-5-38",
    "s1-y-5-39",
    "s1-y-5-40",
    "s1-y-5-41",
    "s1-y-5-42",
    "s1-y-6-6",
    "s1-y-6-7",
    "s1-y-6-8",
    "s1-y-6-9",
    "s1-y-6-10",
    "s1-y-6-11",
    "s1-y-6-12",
    "s1-y-6-13",
    "s1-y-6-14",
    "s1-y-6-15",
    "s1-y-6-16",
    "s1-y-6-17",
    "s1-y-6-19",
    "s1-y-6-20",
    "s1-y-6-21",
    "s1-y-6-22",
    "s1-y-6-23",
    "s1-y-6-24",
    "s1-y-6-25",
    "s1-y-6-26",
    "s1-y-6-27",
    "s1-y-6-28",
    "s1-y-6-29",
    "s1-y-6-30",
    "s1-y-6-32",
    "s1-y-6-33",
    "s1-y-6-34",
    "s1-y-6-35",
    "s1-y-6-36",
    "s1-y-6-37",
    "s1-y-6-38",
    "s1-y-6-39",
    "s1-y-6-40",
    "s1-y-6-41",
    "s1-y-6-42",
    "s1-y-6-43",
    "s1-y-7-6",
    "s1-y-7-7",
    "s1-y-7-8",
    "s1-y-7-9",
    "s1-y-7-10",
    "s1-y-7-11",
    "s1-y-7-12",
    "s1-y-7-13",
    "s1-y-7-14",
    "s1-y-7-15",
    "s1-y-7-16",
    "s1-y-7-17",
    "s1-y-7-19",
    "s1-y-7-20",
    "s1-y-7-21",
    "s1-y-7-22",
    "s1-y-7-23",
    "s1-y-7-24",
    "s1-y-7-25",
    "s1-y-7-26",
    "s1-y-7-27",
    "s1-y-7-28",
    "s1-y-7-29",
    "s1-y-7-30",
    "s1-y-7-32",
    "s1-y-7-33",
    "s1-y-7-34",
    "s1-y-7-35",
    "s1-y-7-36",
    "s1-y-7-37",
    "s1-y-7-38",
    "s1-y-7-39",
    "s1-y-7-40",
    "s1-y-7-41",
    "s1-y-7-42",
    "s1-y-7-43",
    "s1-y-8-6",
    "s1-y-8-7",
    "s1-y-8-8",
    "s1-y-8-9",
    "s1-y-8-10",
    "s1-y-8-11",
    "s1-y-8-12",
    "s1-y-8-13",
    "s1-y-8-14",
    "s1-y-8-15",
    "s1-y-8-16",
    "s1-y-8-17",
    "s1-y-8-19",
    "s1-y-8-20",
    "s1-y-8-21",
    "s1-y-8-22",
    "s1-y-8-23",
    "s1-y-8-24",
    "s1-y-8-25",
    "s1-y-8-26",
    "s1-y-8-27",
    "s1-y-8-28",
    "s1-y-8-29",
    "s1-y-8-30",
    "s1-y-8-32",
    "s1-y-8-33",
    "s1-y-8-34",
    "s1-y-8-35",
    "s1-y-8-36",
    "s1-y-8-37",
    "s1-y-8-38",
    "s1-y-8-39",
    "s1-y-8-40",
    "s1-y-8-41",
    "s1-y-8-42",
    "s1-y-8-43",
    "s1-y-9-6",
    "s1-y-9-7",
    "s1-y-9-8",
    "s1-y-9-9",
    "s1-y-9-10",
    "s1-y-9-11",
    "s1-y-9-12",
    "s1-y-9-13",
    "s1-y-9-14",
    "s1-y-9-15",
    "s1-y-9-16",
    "s1-y-9-17",
    "s1-y-9-19",
    "s1-y-9-20",
    "s1-y-9-21",
    "s1-y-9-22",
    "s1-y-9-23",
    "s1-y-9-24",
    "s1-y-9-25",
    "s1-y-9-26",
    "s1-y-9-27",
    "s1-y-9-28",
    "s1-y-9-29",
    "s1-y-9-30",
    "s1-y-9-32",
    "s1-y-9-33",
    "s1-y-9-34",
    "s1-y-9-35",
    "s1-y-9-36",
    "s1-y-9-37",
    "s1-y-9-38",
    "s1-y-9-39",
    "s1-y-9-40",
    "s1-y-9-41",
    "s1-y-9-42",
    "s1-y-9-43",
    "s1-y-10-6",
    "s1-y-10-7",
    "s1-y-10-8",
    "s1-y-10-9",
    "s1-y-10-10",
    "s1-y-10-11",
    "s1-y-10-12",
    "s1-y-10-13",
    "s1-y-10-14",
    "s1-y-10-15",
    "s1-y-10-16",
    "s1-y-10-17",
    "s1-y-10-19",
    "s1-y-10-20",
    "s1-y-10-21",
    "s1-y-10-22",
    "s1-y-10-23",
    "s1-y-10-24",
    "s1-y-10-25",
    "s1-y-10-26",
    "s1-y-10-27",
    "s1-y-10-28",
    "s1-y-10-29",
    "s1-y-10-30",
    "s1-y-10-32",
    "s1-y-10-33",
    "s1-y-10-34",
    "s1-y-10-35",
    "s1-y-10-36",
    "s1-y-10-37",
    "s1-y-10-38",
    "s1-y-10-39",
    "s1-y-10-40",
    "s1-y-10-41",
    "s1-y-10-42",
    "s1-y-10-43",
    "s1-y-11-6",
    "s1-y-11-7",
    "s1-y-11-8",
    "s1-y-11-9",
    "s1-y-11-10",
    "s1-y-11-11",
    "s1-y-11-12",
    "s1-y-11-13",
    "s1-y-11-14",
    "s1-y-11-15",
    "s1-y-11-16",
    "s1-y-11-17",
    "s1-y-11-19",
    "s1-y-11-20",
    "s1-y-11-21",
    "s1-y-11-22",
    "s1-y-11-23",
    "s1-y-11-24",
    "s1-y-11-25",
    "s1-y-11-26",
    "s1-y-11-27",
    "s1-y-11-28",
    "s1-y-11-29",
    "s1-y-11-30",
    "s1-y-11-32",
    "s1-y-11-33",
    "s1-y-11-34",
    "s1-y-11-35",
    "s1-y-11-36",
    "s1-y-11-37",
    "s1-y-11-38",
    "s1-y-11-39",
    "s1-y-11-40",
    "s1-y-11-41",
    "s1-y-11-42",
    "s1-y-11-43",
    "s1-y-12-8",
    "s1-y-12-9",
    "s1-y-12-10",
    "s1-y-12-11",
    "s1-y-12-12",
    "s1-y-12-13",
    "s1-y-12-14",
    "s1-y-12-15",
    "s1-y-12-16",
    "s1-y-12-17",
    "s1-y-12-19",
    "s1-y-12-20",
    "s1-y-12-21",
    "s1-y-12-22",
    "s1-y-12-23",
    "s1-y-12-24",
    "s1-y-12-25",
    "s1-y-12-26",
    "s1-y-12-27",
    "s1-y-12-28",
    "s1-y-12-29",
    "s1-y-12-30",
    "s1-y-12-32",
    "s1-y-12-33",
    "s1-y-12-34",
    "s1-y-12-35",
    "s1-y-12-36",
    "s1-y-12-37",
    "s1-y-12-38",
    "s1-y-12-39",
    "s1-y-12-40",
    "s1-y-12-41",
    "s1-y-13-1",
    "s1-y-13-2",
    "s1-y-13-3",
    "s1-y-13-4",
    "s1-y-13-45",
    "s1-y-13-46",
    "s1-y-13-47",
    "s1-y-13-48",
    "s1-y-13-8",
    "s1-y-13-9",
    "s1-y-13-10",
    "s1-y-13-11",
    "s1-y-13-12",
    "s1-y-13-13",
    "s1-y-13-14",
    "s1-y-13-15",
    "s1-y-13-16",
    "s1-y-13-17",
    "s1-y-13-19",
    "s1-y-13-20",
    "s1-y-13-21",
    "s1-y-13-22",
    "s1-y-13-23",
    "s1-y-13-24",
    "s1-y-13-25",
    "s1-y-13-26",
    "s1-y-13-27",
    "s1-y-13-28",
    "s1-y-13-29",
    "s1-y-13-30",
    "s1-y-13-32",
    "s1-y-13-33",
    "s1-y-13-34",
    "s1-y-13-35",
    "s1-y-13-36",
    "s1-y-13-37",
    "s1-y-13-38",
    "s1-y-13-39",
    "s1-y-13-40",
    "s1-y-13-41",
    "s1-y-14-45",
    "s1-y-14-46",
    "s1-y-14-47",
    "s1-y-14-48",
    "s1-y-14-1",
    "s1-y-14-2",
    "s1-y-14-3",
    "s1-y-14-4",
    "s1-y-14-8",
    "s1-y-14-9",
    "s1-y-14-10",
    "s1-y-14-11",
    "s1-y-14-12",
    "s1-y-14-13",
    "s1-y-14-14",
    "s1-y-14-15",
    "s1-y-14-16",
    "s1-y-14-17",
    "s1-y-14-19",
    "s1-y-14-20",
    "s1-y-14-21",
    "s1-y-14-22",
    "s1-y-14-23",
    "s1-y-14-24",
    "s1-y-14-25",
    "s1-y-14-26",
    "s1-y-14-27",
    "s1-y-14-28",
    "s1-y-14-29",
    "s1-y-14-30",
    "s1-y-14-32",
    "s1-y-14-33",
    "s1-y-14-34",
    "s1-y-14-35",
    "s1-y-14-36",
    "s1-y-14-37",
    "s1-y-14-38",
    "s1-y-14-39",
    "s1-y-14-40",
    "s1-y-14-41",
    "s1-y-15-45",
    "s1-y-15-46",
    "s1-y-15-47",
    "s1-y-15-48",
    "s1-y-15-1",
    "s1-y-15-2",
    "s1-y-15-3",
    "s1-y-15-4",
    "s1-y-16-1",
    "s1-y-16-2",
    "s1-y-16-3",
    "s1-y-16-4",
    "s1-y-16-10",
    "s1-y-16-11",
    "s1-y-16-12",
    "s1-y-16-13",
    "s1-y-16-14",
    "s1-y-16-15",
    "s1-y-16-16",
    "s1-y-16-20",
    "s1-y-16-21",
    "s1-y-16-22",
    "s1-y-16-23",
    "s1-y-16-24",
    "s1-y-16-25",
    "s1-y-16-26",
    "s1-y-16-27",
    "s1-y-16-28",
    "s1-y-16-33",
    "s1-y-16-34",
    "s1-y-16-35",
    "s1-y-16-36",
    "s1-y-16-37",
    "s1-y-16-38",
    "s1-y-16-39",
    "s1-y-16-45",
    "s1-y-16-46",
    "s1-y-16-47",
    "s1-y-16-48",
    "s1-y-17-1",
    "s1-y-17-2",
    "s1-y-17-3",
    "s1-y-17-4",
    "s1-y-17-10",
    "s1-y-17-11",
    "s1-y-17-12",
    "s1-y-17-13",
    "s1-y-17-14",
    "s1-y-17-15",
    "s1-y-17-16",
    "s1-y-17-20",
    "s1-y-17-21",
    "s1-y-17-22",
    "s1-y-17-23",
    "s1-y-17-24",
    "s1-y-17-25",
    "s1-y-17-26",
    "s1-y-17-27",
    "s1-y-17-28",
    "s1-y-17-33",
    "s1-y-17-34",
    "s1-y-17-35",
    "s1-y-17-36",
    "s1-y-17-37",
    "s1-y-17-38",
    "s1-y-17-39",
    "s1-y-17-45",
    "s1-y-17-46",
    "s1-y-17-47",
    "s1-y-17-48",
    "s1-y-18-1",
    "s1-y-18-2",
    "s1-y-18-3",
    "s1-y-18-4",
    "s1-y-18-5",
    "s1-y-18-9",
    "s1-y-18-10",
    "s1-y-18-11",
    "s1-y-18-12",
    "s1-y-18-13",
    "s1-y-18-14",
    "s1-y-18-15",
    "s1-y-18-16",
    "s1-y-18-20",
    "s1-y-18-21",
    "s1-y-18-22",
    "s1-y-18-23",
    "s1-y-18-24",
    "s1-y-18-25",
    "s1-y-18-26",
    "s1-y-18-27",
    "s1-y-18-28",
    "s1-y-18-29",
    "s1-y-18-33",
    "s1-y-18-34",
    "s1-y-18-35",
    "s1-y-18-36",
    "s1-y-18-37",
    "s1-y-18-38",
    "s1-y-18-39",
    "s1-y-18-40",
    "s1-y-18-44",
    "s1-y-18-45",
    "s1-y-18-46",
    "s1-y-18-47",
    "s1-y-18-48",
    "s1-y-19-1",
    "s1-y-19-2",
    "s1-y-19-3",
    "s1-y-19-4",
    "s1-y-19-5",
    "s1-y-19-6",
    "s1-y-19-8",
    "s1-y-19-9",
    "s1-y-19-10",
    "s1-y-19-11",
    "s1-y-19-12",
    "s1-y-19-13",
    "s1-y-19-14",
    "s1-y-19-15",
    "s1-y-19-16",
    "s1-y-19-17",
    "s1-y-19-19",
    "s1-y-19-20",
    "s1-y-19-21",
    "s1-y-19-22",
    "s1-y-19-23",
    "s1-y-19-24",
    "s1-y-19-25",
    "s1-y-19-26",
    "s1-y-19-27",
    "s1-y-19-28",
    "s1-y-19-29",
    "s1-y-19-30",
    "s1-y-19-32",
    "s1-y-19-33",
    "s1-y-19-34",
    "s1-y-19-35",
    "s1-y-19-36",
    "s1-y-19-37",
    "s1-y-19-38",
    "s1-y-19-39",
    "s1-y-19-40",
    "s1-y-19-41",
    "s1-y-19-43",
    "s1-y-19-44",
    "s1-y-19-45",
    "s1-y-19-46",
    "s1-y-19-47",
    "s1-y-19-48",
    "s1-y-20-1",
    "s1-y-20-2",
    "s1-y-20-3",
    "s1-y-20-4",
    "s1-y-20-5",
    "s1-y-20-6",
    "s1-y-20-8",
    "s1-y-20-9",
    "s1-y-20-10",
    "s1-y-20-11",
    "s1-y-20-12",
    "s1-y-20-13",
    "s1-y-20-14",
    "s1-y-20-15",
    "s1-y-20-16",
    "s1-y-20-17",
    "s1-y-20-19",
    "s1-y-20-20",
    "s1-y-20-21",
    "s1-y-20-22",
    "s1-y-20-23",
    "s1-y-20-24",
    "s1-y-20-25",
    "s1-y-20-26",
    "s1-y-20-27",
    "s1-y-20-28",
    "s1-y-20-29",
    "s1-y-20-30",
    "s1-y-20-32",
    "s1-y-20-33",
    "s1-y-20-34",
    "s1-y-20-35",
    "s1-y-20-36",
    "s1-y-20-37",
    "s1-y-20-38",
    "s1-y-20-39",
    "s1-y-20-40",
    "s1-y-20-41",
    "s1-y-20-43",
    "s1-y-20-44",
    "s1-y-20-45",
    "s1-y-20-46",
    "s1-y-20-47",
    "s1-y-20-48",
    "s1-y-21-1",
    "s1-y-21-2",
    "s1-y-21-3",
    "s1-y-21-4",
    "s1-y-21-5",
    "s1-y-21-6",
    "s1-y-21-8",
    "s1-y-21-9",
    "s1-y-21-10",
    "s1-y-21-11",
    "s1-y-21-12",
    "s1-y-21-13",
    "s1-y-21-14",
    "s1-y-21-15",
    "s1-y-21-16",
    "s1-y-21-17",
    "s1-y-21-19",
    "s1-y-21-20",
    "s1-y-21-21",
    "s1-y-21-22",
    "s1-y-21-23",
    "s1-y-21-24",
    "s1-y-21-25",
    "s1-y-21-26",
    "s1-y-21-27",
    "s1-y-21-28",
    "s1-y-21-29",
    "s1-y-21-30",
    "s1-y-21-32",
    "s1-y-21-33",
    "s1-y-21-34",
    "s1-y-21-35",
    "s1-y-21-36",
    "s1-y-21-37",
    "s1-y-21-38",
    "s1-y-21-39",
    "s1-y-21-40",
    "s1-y-21-41",
    "s1-y-21-43",
    "s1-y-21-44",
    "s1-y-21-45",
    "s1-y-21-46",
    "s1-y-21-47",
    "s1-y-21-48",
    "s1-y-22-1",
    "s1-y-22-2",
    "s1-y-22-3",
    "s1-y-22-4",
    "s1-y-22-5",
    "s1-y-22-6",
    "s1-y-22-8",
    "s1-y-22-9",
    "s1-y-22-10",
    "s1-y-22-11",
    "s1-y-22-12",
    "s1-y-22-13",
    "s1-y-22-14",
    "s1-y-22-15",
    "s1-y-22-16",
    "s1-y-22-17",
    "s1-y-22-19",
    "s1-y-22-20",
    "s1-y-22-21",
    "s1-y-22-22",
    "s1-y-22-23",
    "s1-y-22-24",
    "s1-y-22-25",
    "s1-y-22-26",
    "s1-y-22-27",
    "s1-y-22-28",
    "s1-y-22-29",
    "s1-y-22-30",
    "s1-y-22-32",
    "s1-y-22-33",
    "s1-y-22-34",
    "s1-y-22-35",
    "s1-y-22-36",
    "s1-y-22-37",
    "s1-y-22-38",
    "s1-y-22-39",
    "s1-y-22-40",
    "s1-y-22-41",
    "s1-y-22-43",
    "s1-y-22-44",
    "s1-y-22-45",
    "s1-y-22-46",
    "s1-y-22-47",
    "s1-y-22-48",
    "s1-y-23-1",
    "s1-y-23-2",
    "s1-y-23-3",
    "s1-y-23-4",
    "s1-y-23-5",
    "s1-y-23-6",
    "s1-y-23-8",
    "s1-y-23-9",
    "s1-y-23-10",
    "s1-y-23-11",
    "s1-y-23-12",
    "s1-y-23-13",
    "s1-y-23-14",
    "s1-y-23-15",
    "s1-y-23-16",
    "s1-y-23-17",
    "s1-y-23-19",
    "s1-y-23-20",
    "s1-y-23-21",
    "s1-y-23-22",
    "s1-y-23-23",
    "s1-y-23-24",
    "s1-y-23-25",
    "s1-y-23-26",
    "s1-y-23-27",
    "s1-y-23-28",
    "s1-y-23-29",
    "s1-y-23-30",
    "s1-y-23-32",
    "s1-y-23-33",
    "s1-y-23-34",
    "s1-y-23-35",
    "s1-y-23-36",
    "s1-y-23-37",
    "s1-y-23-38",
    "s1-y-23-39",
    "s1-y-23-40",
    "s1-y-23-41",
    "s1-y-23-43",
    "s1-y-23-44",
    "s1-y-23-45",
    "s1-y-23-46",
    "s1-y-23-47",
    "s1-y-23-48",
    "s1-y-24-1",
    "s1-y-24-2",
    "s1-y-24-3",
    "s1-y-24-4",
    "s1-y-24-5",
    "s1-y-24-6",
    "s1-y-24-8",
    "s1-y-24-9",
    "s1-y-24-10",
    "s1-y-24-11",
    "s1-y-24-12",
    "s1-y-24-13",
    "s1-y-24-14",
    "s1-y-24-15",
    "s1-y-24-16",
    "s1-y-24-17",
    "s1-y-24-19",
    "s1-y-24-20",
    "s1-y-24-21",
    "s1-y-24-22",
    "s1-y-24-23",
    "s1-y-24-24",
    "s1-y-24-25",
    "s1-y-24-26",
    "s1-y-24-27",
    "s1-y-24-28",
    "s1-y-24-29",
    "s1-y-24-30",
    "s1-y-24-32",
    "s1-y-24-33",
    "s1-y-24-34",
    "s1-y-24-35",
    "s1-y-24-36",
    "s1-y-24-37",
    "s1-y-24-38",
    "s1-y-24-39",
    "s1-y-24-40",
    "s1-y-24-41",
    "s1-y-24-43",
    "s1-y-24-44",
    "s1-y-24-45",
    "s1-y-24-46",
    "s1-y-24-47",
    "s1-y-24-48",
    "s1-y-25-1",
    "s1-y-25-2",
    "s1-y-25-3",
    "s1-y-25-4",
    "s1-y-25-5",
    "s1-y-25-6",
    "s1-y-25-8",
    "s1-y-25-9",
    "s1-y-25-10",
    "s1-y-25-11",
    "s1-y-25-12",
    "s1-y-25-13",
    "s1-y-25-14",
    "s1-y-25-15",
    "s1-y-25-16",
    "s1-y-25-17",
    "s1-y-25-19",
    "s1-y-25-20",
    "s1-y-25-21",
    "s1-y-25-22",
    "s1-y-25-23",
    "s1-y-25-24",
    "s1-y-25-25",
    "s1-y-25-26",
    "s1-y-25-27",
    "s1-y-25-28",
    "s1-y-25-29",
    "s1-y-25-30",
    "s1-y-25-32",
    "s1-y-25-33",
    "s1-y-25-34",
    "s1-y-25-35",
    "s1-y-25-36",
    "s1-y-25-37",
    "s1-y-25-38",
    "s1-y-25-39",
    "s1-y-25-40",
    "s1-y-25-41",
    "s1-y-25-43",
    "s1-y-25-44",
    "s1-y-25-45",
    "s1-y-25-46",
    "s1-y-25-47",
    "s1-y-25-48",
  ];
  const seat_section_2 = [
    "s2-y-1-10",
    "s2-y-1-11",
    "s2-y-1-12",
    "s2-y-1-13",
    "s2-y-1-14",
    "s2-y-1-15",
    "s2-y-1-16",
    "s2-y-1-17",
    "s2-y-1-19",
    "s2-y-1-20",
    "s2-y-1-21",
    "s2-y-1-22",
    "s2-y-1-23",
    "s2-y-1-24",
    "s2-y-1-25",
    "s2-y-1-26",
    "s2-y-1-27",
    "s2-y-1-28",
    "s2-y-1-29",
    "s2-y-1-30",
    "s2-y-1-32",
    "s2-y-1-33",
    "s2-y-1-34",
    "s2-y-1-35",
    "s2-y-1-36",
    "s2-y-1-37",
    "s2-y-1-38",
    "s2-y-1-39",
    "s2-y-2-9",
    "s2-y-2-10",
    "s2-y-2-11",
    "s2-y-2-12",
    "s2-y-2-13",
    "s2-y-2-14",
    "s2-y-2-15",
    "s2-y-2-16",
    "s2-y-2-17",
    "s2-y-2-19",
    "s2-y-2-20",
    "s2-y-2-21",
    "s2-y-2-22",
    "s2-y-2-23",
    "s2-y-2-24",
    "s2-y-2-25",
    "s2-y-2-26",
    "s2-y-2-27",
    "s2-y-2-28",
    "s2-y-2-29",
    "s2-y-2-30",
    "s2-y-2-32",
    "s2-y-2-33",
    "s2-y-2-34",
    "s2-y-2-35",
    "s2-y-2-36",
    "s2-y-2-37",
    "s2-y-2-38",
    "s2-y-2-39",
    "s2-y-2-40",
    "s2-y-3-8",
    "s2-y-3-9",
    "s2-y-3-10",
    "s2-y-3-11",
    "s2-y-3-12",
    "s2-y-3-13",
    "s2-y-3-14",
    "s2-y-3-15",
    "s2-y-3-16",
    "s2-y-3-17",
    "s2-y-3-19",
    "s2-y-3-20",
    "s2-y-3-21",
    "s2-y-3-22",
    "s2-y-3-23",
    "s2-y-3-24",
    "s2-y-3-25",
    "s2-y-3-26",
    "s2-y-3-27",
    "s2-y-3-28",
    "s2-y-3-29",
    "s2-y-3-30",
    "s2-y-3-32",
    "s2-y-3-33",
    "s2-y-3-34",
    "s2-y-3-35",
    "s2-y-3-36",
    "s2-y-3-37",
    "s2-y-3-38",
    "s2-y-3-39",
    "s2-y-3-40",
    "s2-y-3-41",
    "s2-y-4-8",
    "s2-y-4-9",
    "s2-y-4-10",
    "s2-y-4-11",
    "s2-y-4-12",
    "s2-y-4-13",
    "s2-y-4-14",
    "s2-y-4-15",
    "s2-y-4-16",
    "s2-y-4-17",
    "s2-y-4-19",
    "s2-y-4-20",
    "s2-y-4-21",
    "s2-y-4-22",
    "s2-y-4-23",
    "s2-y-4-24",
    "s2-y-4-25",
    "s2-y-4-26",
    "s2-y-4-27",
    "s2-y-4-28",
    "s2-y-4-29",
    "s2-y-4-30",
    "s2-y-4-32",
    "s2-y-4-33",
    "s2-y-4-34",
    "s2-y-4-35",
    "s2-y-4-36",
    "s2-y-4-37",
    "s2-y-4-38",
    "s2-y-4-39",
    "s2-y-4-40",
    "s2-y-4-41",
    "s2-y-5-7",
    "s2-y-5-8",
    "s2-y-5-9",
    "s2-y-5-10",
    "s2-y-5-11",
    "s2-y-5-12",
    "s2-y-5-13",
    "s2-y-5-14",
    "s2-y-5-15",
    "s2-y-5-16",
    "s2-y-5-17",
    "s2-y-5-19",
    "s2-y-5-20",
    "s2-y-5-21",
    "s2-y-5-22",
    "s2-y-5-23",
    "s2-y-5-24",
    "s2-y-5-25",
    "s2-y-5-26",
    "s2-y-5-27",
    "s2-y-5-28",
    "s2-y-5-29",
    "s2-y-5-30",
    "s2-y-5-32",
    "s2-y-5-33",
    "s2-y-5-34",
    "s2-y-5-35",
    "s2-y-5-36",
    "s2-y-5-37",
    "s2-y-5-38",
    "s2-y-5-39",
    "s2-y-5-40",
    "s2-y-5-41",
    "s2-y-5-42",
    "s2-y-6-6",
    "s2-y-6-7",
    "s2-y-6-8",
    "s2-y-6-9",
    "s2-y-6-10",
    "s2-y-6-11",
    "s2-y-6-12",
    "s2-y-6-13",
    "s2-y-6-14",
    "s2-y-6-15",
    "s2-y-6-16",
    "s2-y-6-17",
    "s2-y-6-19",
    "s2-y-6-20",
    "s2-y-6-21",
    "s2-y-6-22",
    "s2-y-6-23",
    "s2-y-6-24",
    "s2-y-6-25",
    "s2-y-6-26",
    "s2-y-6-27",
    "s2-y-6-28",
    "s2-y-6-29",
    "s2-y-6-30",
    "s2-y-6-32",
    "s2-y-6-33",
    "s2-y-6-34",
    "s2-y-6-35",
    "s2-y-6-36",
    "s2-y-6-37",
    "s2-y-6-38",
    "s2-y-6-39",
    "s2-y-6-40",
    "s2-y-6-41",
    "s2-y-6-42",
    "s2-y-6-43",
    "s2-y-7-6",
    "s2-y-7-7",
    "s2-y-7-8",
    "s2-y-7-9",
    "s2-y-7-10",
    "s2-y-7-11",
    "s2-y-7-12",
    "s2-y-7-13",
    "s2-y-7-14",
    "s2-y-7-15",
    "s2-y-7-16",
    "s2-y-7-17",
    "s2-y-7-19",
    "s2-y-7-20",
    "s2-y-7-21",
    "s2-y-7-22",
    "s2-y-7-23",
    "s2-y-7-24",
    "s2-y-7-25",
    "s2-y-7-26",
    "s2-y-7-27",
    "s2-y-7-28",
    "s2-y-7-29",
    "s2-y-7-30",
    "s2-y-7-32",
    "s2-y-7-33",
    "s2-y-7-34",
    "s2-y-7-35",
    "s2-y-7-36",
    "s2-y-7-37",
    "s2-y-7-38",
    "s2-y-7-39",
    "s2-y-7-40",
    "s2-y-7-41",
    "s2-y-7-42",
    "s2-y-7-43",
    "s2-y-8-6",
    "s2-y-8-7",
    "s2-y-8-8",
    "s2-y-8-9",
    "s2-y-8-10",
    "s2-y-8-11",
    "s2-y-8-12",
    "s2-y-8-13",
    "s2-y-8-14",
    "s2-y-8-15",
    "s2-y-8-16",
    "s2-y-8-17",
    "s2-y-8-19",
    "s2-y-8-20",
    "s2-y-8-21",
    "s2-y-8-22",
    "s2-y-8-23",
    "s2-y-8-24",
    "s2-y-8-25",
    "s2-y-8-26",
    "s2-y-8-27",
    "s2-y-8-28",
    "s2-y-8-29",
    "s2-y-8-30",
    "s2-y-8-32",
    "s2-y-8-33",
    "s2-y-8-34",
    "s2-y-8-35",
    "s2-y-8-36",
    "s2-y-8-37",
    "s2-y-8-38",
    "s2-y-8-39",
    "s2-y-8-40",
    "s2-y-8-41",
    "s2-y-8-42",
    "s2-y-8-43",
    "s2-y-9-6",
    "s2-y-9-7",
    "s2-y-9-8",
    "s2-y-9-9",
    "s2-y-9-10",
    "s2-y-9-11",
    "s2-y-9-12",
    "s2-y-9-13",
    "s2-y-9-14",
    "s2-y-9-15",
    "s2-y-9-16",
    "s2-y-9-17",
    "s2-y-9-19",
    "s2-y-9-20",
    "s2-y-9-21",
    "s2-y-9-22",
    "s2-y-9-23",
    "s2-y-9-24",
    "s2-y-9-25",
    "s2-y-9-26",
    "s2-y-9-27",
    "s2-y-9-28",
    "s2-y-9-29",
    "s2-y-9-30",
    "s2-y-9-32",
    "s2-y-9-33",
    "s2-y-9-34",
    "s2-y-9-35",
    "s2-y-9-36",
    "s2-y-9-37",
    "s2-y-9-38",
    "s2-y-9-39",
    "s2-y-9-40",
    "s2-y-9-41",
    "s2-y-9-42",
    "s2-y-9-43",
    "s2-y-10-6",
    "s2-y-10-7",
    "s2-y-10-8",
    "s2-y-10-9",
    "s2-y-10-10",
    "s2-y-10-11",
    "s2-y-10-12",
    "s2-y-10-13",
    "s2-y-10-14",
    "s2-y-10-15",
    "s2-y-10-16",
    "s2-y-10-17",
    "s2-y-10-19",
    "s2-y-10-20",
    "s2-y-10-21",
    "s2-y-10-22",
    "s2-y-10-23",
    "s2-y-10-24",
    "s2-y-10-25",
    "s2-y-10-26",
    "s2-y-10-27",
    "s2-y-10-28",
    "s2-y-10-29",
    "s2-y-10-30",
    "s2-y-10-32",
    "s2-y-10-33",
    "s2-y-10-34",
    "s2-y-10-35",
    "s2-y-10-36",
    "s2-y-10-37",
    "s2-y-10-38",
    "s2-y-10-39",
    "s2-y-10-40",
    "s2-y-10-41",
    "s2-y-10-42",
    "s2-y-10-43",
    "s2-y-11-6",
    "s2-y-11-7",
    "s2-y-11-8",
    "s2-y-11-9",
    "s2-y-11-10",
    "s2-y-11-11",
    "s2-y-11-12",
    "s2-y-11-13",
    "s2-y-11-14",
    "s2-y-11-15",
    "s2-y-11-16",
    "s2-y-11-17",
    "s2-y-11-19",
    "s2-y-11-20",
    "s2-y-11-21",
    "s2-y-11-22",
    "s2-y-11-23",
    "s2-y-11-24",
    "s2-y-11-25",
    "s2-y-11-26",
    "s2-y-11-27",
    "s2-y-11-28",
    "s2-y-11-29",
    "s2-y-11-30",
    "s2-y-11-32",
    "s2-y-11-33",
    "s2-y-11-34",
    "s2-y-11-35",
    "s2-y-11-36",
    "s2-y-11-37",
    "s2-y-11-38",
    "s2-y-11-39",
    "s2-y-11-40",
    "s2-y-11-41",
    "s2-y-11-42",
    "s2-y-11-43",
    "s2-y-12-8",
    "s2-y-12-9",
    "s2-y-12-10",
    "s2-y-12-11",
    "s2-y-12-12",
    "s2-y-12-13",
    "s2-y-12-14",
    "s2-y-12-15",
    "s2-y-12-16",
    "s2-y-12-17",
    "s2-y-12-19",
    "s2-y-12-20",
    "s2-y-12-21",
    "s2-y-12-22",
    "s2-y-12-23",
    "s2-y-12-24",
    "s2-y-12-25",
    "s2-y-12-26",
    "s2-y-12-27",
    "s2-y-12-28",
    "s2-y-12-29",
    "s2-y-12-30",
    "s2-y-12-32",
    "s2-y-12-33",
    "s2-y-12-34",
    "s2-y-12-35",
    "s2-y-12-36",
    "s2-y-12-37",
    "s2-y-12-38",
    "s2-y-12-39",
    "s2-y-12-40",
    "s2-y-12-41",
    "s2-y-13-1",
    "s2-y-13-2",
    "s2-y-13-3",
    "s2-y-13-4",
    "s2-y-13-45",
    "s2-y-13-46",
    "s2-y-13-47",
    "s2-y-13-48",
    "s2-y-13-8",
    "s2-y-13-9",
    "s2-y-13-10",
    "s2-y-13-11",
    "s2-y-13-12",
    "s2-y-13-13",
    "s2-y-13-14",
    "s2-y-13-15",
    "s2-y-13-16",
    "s2-y-13-17",
    "s2-y-13-19",
    "s2-y-13-20",
    "s2-y-13-21",
    "s2-y-13-22",
    "s2-y-13-23",
    "s2-y-13-24",
    "s2-y-13-25",
    "s2-y-13-26",
    "s2-y-13-27",
    "s2-y-13-28",
    "s2-y-13-29",
    "s2-y-13-30",
    "s2-y-13-32",
    "s2-y-13-33",
    "s2-y-13-34",
    "s2-y-13-35",
    "s2-y-13-36",
    "s2-y-13-37",
    "s2-y-13-38",
    "s2-y-13-39",
    "s2-y-13-40",
    "s2-y-13-41",
    "s2-y-14-45",
    "s2-y-14-46",
    "s2-y-14-47",
    "s2-y-14-48",
    "s2-y-14-1",
    "s2-y-14-2",
    "s2-y-14-3",
    "s2-y-14-4",
    "s2-y-14-8",
    "s2-y-14-9",
    "s2-y-14-10",
    "s2-y-14-11",
    "s2-y-14-12",
    "s2-y-14-13",
    "s2-y-14-14",
    "s2-y-14-15",
    "s2-y-14-16",
    "s2-y-14-17",
    "s2-y-14-19",
    "s2-y-14-20",
    "s2-y-14-21",
    "s2-y-14-22",
    "s2-y-14-23",
    "s2-y-14-24",
    "s2-y-14-25",
    "s2-y-14-26",
    "s2-y-14-27",
    "s2-y-14-28",
    "s2-y-14-29",
    "s2-y-14-30",
    "s2-y-14-32",
    "s2-y-14-33",
    "s2-y-14-34",
    "s2-y-14-35",
    "s2-y-14-36",
    "s2-y-14-37",
    "s2-y-14-38",
    "s2-y-14-39",
    "s2-y-14-40",
    "s2-y-14-41",
    "s2-y-15-45",
    "s2-y-15-46",
    "s2-y-15-47",
    "s2-y-15-48",
    "s2-y-15-1",
    "s2-y-15-2",
    "s2-y-15-3",
    "s2-y-15-4",
    "s2-y-16-1",
    "s2-y-16-2",
    "s2-y-16-3",
    "s2-y-16-4",
    "s2-y-16-10",
    "s2-y-16-11",
    "s2-y-16-12",
    "s2-y-16-13",
    "s2-y-16-14",
    "s2-y-16-15",
    "s2-y-16-16",
    "s2-y-16-20",
    "s2-y-16-21",
    "s2-y-16-22",
    "s2-y-16-23",
    "s2-y-16-24",
    "s2-y-16-25",
    "s2-y-16-26",
    "s2-y-16-27",
    "s2-y-16-28",
    "s2-y-16-33",
    "s2-y-16-34",
    "s2-y-16-35",
    "s2-y-16-36",
    "s2-y-16-37",
    "s2-y-16-38",
    "s2-y-16-39",
    "s2-y-16-45",
    "s2-y-16-46",
    "s2-y-16-47",
    "s2-y-16-48",
    "s2-y-17-1",
    "s2-y-17-2",
    "s2-y-17-3",
    "s2-y-17-4",
    "s2-y-17-10",
    "s2-y-17-11",
    "s2-y-17-12",
    "s2-y-17-13",
    "s2-y-17-14",
    "s2-y-17-15",
    "s2-y-17-16",
    "s2-y-17-20",
    "s2-y-17-21",
    "s2-y-17-22",
    "s2-y-17-23",
    "s2-y-17-24",
    "s2-y-17-25",
    "s2-y-17-26",
    "s2-y-17-27",
    "s2-y-17-28",
    "s2-y-17-33",
    "s2-y-17-34",
    "s2-y-17-35",
    "s2-y-17-36",
    "s2-y-17-37",
    "s2-y-17-38",
    "s2-y-17-39",
    "s2-y-17-45",
    "s2-y-17-46",
    "s2-y-17-47",
    "s2-y-17-48",
    "s2-y-18-1",
    "s2-y-18-2",
    "s2-y-18-3",
    "s2-y-18-4",
    "s2-y-18-5",
    "s2-y-18-9",
    "s2-y-18-10",
    "s2-y-18-11",
    "s2-y-18-12",
    "s2-y-18-13",
    "s2-y-18-14",
    "s2-y-18-15",
    "s2-y-18-16",
    "s2-y-18-20",
    "s2-y-18-21",
    "s2-y-18-22",
    "s2-y-18-23",
    "s2-y-18-24",
    "s2-y-18-25",
    "s2-y-18-26",
    "s2-y-18-27",
    "s2-y-18-28",
    "s2-y-18-29",
    "s2-y-18-33",
    "s2-y-18-34",
    "s2-y-18-35",
    "s2-y-18-36",
    "s2-y-18-37",
    "s2-y-18-38",
    "s2-y-18-39",
    "s2-y-18-40",
    "s2-y-18-44",
    "s2-y-18-45",
    "s2-y-18-46",
    "s2-y-18-47",
    "s2-y-18-48",
    "s2-y-19-1",
    "s2-y-19-2",
    "s2-y-19-3",
    "s2-y-19-4",
    "s2-y-19-5",
    "s2-y-19-6",
    "s2-y-19-8",
    "s2-y-19-9",
    "s2-y-19-10",
    "s2-y-19-11",
    "s2-y-19-12",
    "s2-y-19-13",
    "s2-y-19-14",
    "s2-y-19-15",
    "s2-y-19-16",
    "s2-y-19-17",
    "s2-y-19-19",
    "s2-y-19-20",
    "s2-y-19-21",
    "s2-y-19-22",
    "s2-y-19-23",
    "s2-y-19-24",
    "s2-y-19-25",
    "s2-y-19-26",
    "s2-y-19-27",
    "s2-y-19-28",
    "s2-y-19-29",
    "s2-y-19-30",
    "s2-y-19-32",
    "s2-y-19-33",
    "s2-y-19-34",
    "s2-y-19-35",
    "s2-y-19-36",
    "s2-y-19-37",
    "s2-y-19-38",
    "s2-y-19-39",
    "s2-y-19-40",
    "s2-y-19-41",
    "s2-y-19-43",
    "s2-y-19-44",
    "s2-y-19-45",
    "s2-y-19-46",
    "s2-y-19-47",
    "s2-y-19-48",
    "s2-y-20-1",
    "s2-y-20-2",
    "s2-y-20-3",
    "s2-y-20-4",
    "s2-y-20-5",
    "s2-y-20-6",
    "s2-y-20-8",
    "s2-y-20-9",
    "s2-y-20-10",
    "s2-y-20-11",
    "s2-y-20-12",
    "s2-y-20-13",
    "s2-y-20-14",
    "s2-y-20-15",
    "s2-y-20-16",
    "s2-y-20-17",
    "s2-y-20-19",
    "s2-y-20-20",
    "s2-y-20-21",
    "s2-y-20-22",
    "s2-y-20-23",
    "s2-y-20-24",
    "s2-y-20-25",
    "s2-y-20-26",
    "s2-y-20-27",
    "s2-y-20-28",
    "s2-y-20-29",
    "s2-y-20-30",
    "s2-y-20-32",
    "s2-y-20-33",
    "s2-y-20-34",
    "s2-y-20-35",
    "s2-y-20-36",
    "s2-y-20-37",
    "s2-y-20-38",
    "s2-y-20-39",
    "s2-y-20-40",
    "s2-y-20-41",
    "s2-y-20-43",
    "s2-y-20-44",
    "s2-y-20-45",
    "s2-y-20-46",
    "s2-y-20-47",
    "s2-y-20-48",
    "s2-y-21-1",
    "s2-y-21-2",
    "s2-y-21-3",
    "s2-y-21-4",
    "s2-y-21-5",
    "s2-y-21-6",
    "s2-y-21-8",
    "s2-y-21-9",
    "s2-y-21-10",
    "s2-y-21-11",
    "s2-y-21-12",
    "s2-y-21-13",
    "s2-y-21-14",
    "s2-y-21-15",
    "s2-y-21-16",
    "s2-y-21-17",
    "s2-y-21-19",
    "s2-y-21-20",
    "s2-y-21-21",
    "s2-y-21-22",
    "s2-y-21-23",
    "s2-y-21-24",
    "s2-y-21-25",
    "s2-y-21-26",
    "s2-y-21-27",
    "s2-y-21-28",
    "s2-y-21-29",
    "s2-y-21-30",
    "s2-y-21-32",
    "s2-y-21-33",
    "s2-y-21-34",
    "s2-y-21-35",
    "s2-y-21-36",
    "s2-y-21-37",
    "s2-y-21-38",
    "s2-y-21-39",
    "s2-y-21-40",
    "s2-y-21-41",
    "s2-y-21-43",
    "s2-y-21-44",
    "s2-y-21-45",
    "s2-y-21-46",
    "s2-y-21-47",
    "s2-y-21-48",
    "s2-y-22-1",
    "s2-y-22-2",
    "s2-y-22-3",
    "s2-y-22-4",
    "s2-y-22-5",
    "s2-y-22-6",
    "s2-y-22-8",
    "s2-y-22-9",
    "s2-y-22-10",
    "s2-y-22-11",
    "s2-y-22-12",
    "s2-y-22-13",
    "s2-y-22-14",
    "s2-y-22-15",
    "s2-y-22-16",
    "s2-y-22-17",
    "s2-y-22-19",
    "s2-y-22-20",
    "s2-y-22-21",
    "s2-y-22-22",
    "s2-y-22-23",
    "s2-y-22-24",
    "s2-y-22-25",
    "s2-y-22-26",
    "s2-y-22-27",
    "s2-y-22-28",
    "s2-y-22-29",
    "s2-y-22-30",
    "s2-y-22-32",
    "s2-y-22-33",
    "s2-y-22-34",
    "s2-y-22-35",
    "s2-y-22-36",
    "s2-y-22-37",
    "s2-y-22-38",
    "s2-y-22-39",
    "s2-y-22-40",
    "s2-y-22-41",
    "s2-y-22-43",
    "s2-y-22-44",
    "s2-y-22-45",
    "s2-y-22-46",
    "s2-y-22-47",
    "s2-y-22-48",
    "s2-y-23-1",
    "s2-y-23-2",
    "s2-y-23-3",
    "s2-y-23-4",
    "s2-y-23-5",
    "s2-y-23-6",
    "s2-y-23-8",
    "s2-y-23-9",
    "s2-y-23-10",
    "s2-y-23-11",
    "s2-y-23-12",
    "s2-y-23-13",
    "s2-y-23-14",
    "s2-y-23-15",
    "s2-y-23-16",
    "s2-y-23-17",
    "s2-y-23-19",
    "s2-y-23-20",
    "s2-y-23-21",
    "s2-y-23-22",
    "s2-y-23-23",
    "s2-y-23-24",
    "s2-y-23-25",
    "s2-y-23-26",
    "s2-y-23-27",
    "s2-y-23-28",
    "s2-y-23-29",
    "s2-y-23-30",
    "s2-y-23-32",
    "s2-y-23-33",
    "s2-y-23-34",
    "s2-y-23-35",
    "s2-y-23-36",
    "s2-y-23-37",
    "s2-y-23-38",
    "s2-y-23-39",
    "s2-y-23-40",
    "s2-y-23-41",
    "s2-y-23-43",
    "s2-y-23-44",
    "s2-y-23-45",
    "s2-y-23-46",
    "s2-y-23-47",
    "s2-y-23-48",
    "s2-y-24-1",
    "s2-y-24-2",
    "s2-y-24-3",
    "s2-y-24-4",
    "s2-y-24-5",
    "s2-y-24-6",
    "s2-y-24-8",
    "s2-y-24-9",
    "s2-y-24-10",
    "s2-y-24-11",
    "s2-y-24-12",
    "s2-y-24-13",
    "s2-y-24-14",
    "s2-y-24-15",
    "s2-y-24-16",
    "s2-y-24-17",
    "s2-y-24-19",
    "s2-y-24-20",
    "s2-y-24-21",
    "s2-y-24-22",
    "s2-y-24-23",
    "s2-y-24-24",
    "s2-y-24-25",
    "s2-y-24-26",
    "s2-y-24-27",
    "s2-y-24-28",
    "s2-y-24-29",
    "s2-y-24-30",
    "s2-y-24-32",
    "s2-y-24-33",
    "s2-y-24-34",
    "s2-y-24-35",
    "s2-y-24-36",
    "s2-y-24-37",
    "s2-y-24-38",
    "s2-y-24-39",
    "s2-y-24-40",
    "s2-y-24-41",
    "s2-y-24-43",
    "s2-y-24-44",
    "s2-y-24-45",
    "s2-y-24-46",
    "s2-y-24-47",
    "s2-y-24-48",
    "s2-y-25-1",
    "s2-y-25-2",
    "s2-y-25-3",
    "s2-y-25-4",
    "s2-y-25-5",
    "s2-y-25-6",
    "s2-y-25-8",
    "s2-y-25-9",
    "s2-y-25-10",
    "s2-y-25-11",
    "s2-y-25-12",
    "s2-y-25-13",
    "s2-y-25-14",
    "s2-y-25-15",
    "s2-y-25-16",
    "s2-y-25-17",
    "s2-y-25-19",
    "s2-y-25-20",
    "s2-y-25-21",
    "s2-y-25-22",
    "s2-y-25-23",
    "s2-y-25-24",
    "s2-y-25-25",
    "s2-y-25-26",
    "s2-y-25-27",
    "s2-y-25-28",
    "s2-y-25-29",
    "s2-y-25-30",
    "s2-y-25-32",
    "s2-y-25-33",
    "s2-y-25-34",
    "s2-y-25-35",
    "s2-y-25-36",
    "s2-y-25-37",
    "s2-y-25-38",
    "s2-y-25-39",
    "s2-y-25-40",
    "s2-y-25-41",
    "s2-y-25-43",
    "s2-y-25-44",
    "s2-y-25-45",
    "s2-y-25-46",
    "s2-y-25-47",
    "s2-y-25-48",
  ];
  const seat_section_3 = [
    "s3-y-1-10",
    "s3-y-1-11",
    "s3-y-1-12",
    "s3-y-1-13",
    "s3-y-1-14",
    "s3-y-1-15",
    "s3-y-1-16",
    "s3-y-1-17",
    "s3-y-1-19",
    "s3-y-1-20",
    "s3-y-1-21",
    "s3-y-1-22",
    "s3-y-1-23",
    "s3-y-1-24",
    "s3-y-1-25",
    "s3-y-1-26",
    "s3-y-1-27",
    "s3-y-1-28",
    "s3-y-1-29",
    "s3-y-1-30",
    "s3-y-1-32",
    "s3-y-1-33",
    "s3-y-1-34",
    "s3-y-1-35",
    "s3-y-1-36",
    "s3-y-1-37",
    "s3-y-1-38",
    "s3-y-1-39",
    "s3-y-2-9",
    "s3-y-2-10",
    "s3-y-2-11",
    "s3-y-2-12",
    "s3-y-2-13",
    "s3-y-2-14",
    "s3-y-2-15",
    "s3-y-2-16",
    "s3-y-2-17",
    "s3-y-2-19",
    "s3-y-2-20",
    "s3-y-2-21",
    "s3-y-2-22",
    "s3-y-2-23",
    "s3-y-2-24",
    "s3-y-2-25",
    "s3-y-2-26",
    "s3-y-2-27",
    "s3-y-2-28",
    "s3-y-2-29",
    "s3-y-2-30",
    "s3-y-2-32",
    "s3-y-2-33",
    "s3-y-2-34",
    "s3-y-2-35",
    "s3-y-2-36",
    "s3-y-2-37",
    "s3-y-2-38",
    "s3-y-2-39",
    "s3-y-2-40",
    "s3-y-3-8",
    "s3-y-3-9",
    "s3-y-3-10",
    "s3-y-3-11",
    "s3-y-3-12",
    "s3-y-3-13",
    "s3-y-3-14",
    "s3-y-3-15",
    "s3-y-3-16",
    "s3-y-3-17",
    "s3-y-3-19",
    "s3-y-3-20",
    "s3-y-3-21",
    "s3-y-3-22",
    "s3-y-3-23",
    "s3-y-3-24",
    "s3-y-3-25",
    "s3-y-3-26",
    "s3-y-3-27",
    "s3-y-3-28",
    "s3-y-3-29",
    "s3-y-3-30",
    "s3-y-3-32",
    "s3-y-3-33",
    "s3-y-3-34",
    "s3-y-3-35",
    "s3-y-3-36",
    "s3-y-3-37",
    "s3-y-3-38",
    "s3-y-3-39",
    "s3-y-3-40",
    "s3-y-3-41",
    "s3-y-4-8",
    "s3-y-4-9",
    "s3-y-4-10",
    "s3-y-4-11",
    "s3-y-4-12",
    "s3-y-4-13",
    "s3-y-4-14",
    "s3-y-4-15",
    "s3-y-4-16",
    "s3-y-4-17",
    "s3-y-4-19",
    "s3-y-4-20",
    "s3-y-4-21",
    "s3-y-4-22",
    "s3-y-4-23",
    "s3-y-4-24",
    "s3-y-4-25",
    "s3-y-4-26",
    "s3-y-4-27",
    "s3-y-4-28",
    "s3-y-4-29",
    "s3-y-4-30",
    "s3-y-4-32",
    "s3-y-4-33",
    "s3-y-4-34",
    "s3-y-4-35",
    "s3-y-4-36",
    "s3-y-4-37",
    "s3-y-4-38",
    "s3-y-4-39",
    "s3-y-4-40",
    "s3-y-4-41",
    "s3-y-5-7",
    "s3-y-5-8",
    "s3-y-5-9",
    "s3-y-5-10",
    "s3-y-5-11",
    "s3-y-5-12",
    "s3-y-5-13",
    "s3-y-5-14",
    "s3-y-5-15",
    "s3-y-5-16",
    "s3-y-5-17",
    "s3-y-5-19",
    "s3-y-5-20",
    "s3-y-5-21",
    "s3-y-5-22",
    "s3-y-5-23",
    "s3-y-5-24",
    "s3-y-5-25",
    "s3-y-5-26",
    "s3-y-5-27",
    "s3-y-5-28",
    "s3-y-5-29",
    "s3-y-5-30",
    "s3-y-5-32",
    "s3-y-5-33",
    "s3-y-5-34",
    "s3-y-5-35",
    "s3-y-5-36",
    "s3-y-5-37",
    "s3-y-5-38",
    "s3-y-5-39",
    "s3-y-5-40",
    "s3-y-5-41",
    "s3-y-5-42",
    "s3-y-6-6",
    "s3-y-6-7",
    "s3-y-6-8",
    "s3-y-6-9",
    "s3-y-6-10",
    "s3-y-6-11",
    "s3-y-6-12",
    "s3-y-6-13",
    "s3-y-6-14",
    "s3-y-6-15",
    "s3-y-6-16",
    "s3-y-6-17",
    "s3-y-6-19",
    "s3-y-6-20",
    "s3-y-6-21",
    "s3-y-6-22",
    "s3-y-6-23",
    "s3-y-6-24",
    "s3-y-6-25",
    "s3-y-6-26",
    "s3-y-6-27",
    "s3-y-6-28",
    "s3-y-6-29",
    "s3-y-6-30",
    "s3-y-6-32",
    "s3-y-6-33",
    "s3-y-6-34",
    "s3-y-6-35",
    "s3-y-6-36",
    "s3-y-6-37",
    "s3-y-6-38",
    "s3-y-6-39",
    "s3-y-6-40",
    "s3-y-6-41",
    "s3-y-6-42",
    "s3-y-6-43",
    "s3-y-7-6",
    "s3-y-7-7",
    "s3-y-7-8",
    "s3-y-7-9",
    "s3-y-7-10",
    "s3-y-7-11",
    "s3-y-7-12",
    "s3-y-7-13",
    "s3-y-7-14",
    "s3-y-7-15",
    "s3-y-7-16",
    "s3-y-7-17",
    "s3-y-7-19",
    "s3-y-7-20",
    "s3-y-7-21",
    "s3-y-7-22",
    "s3-y-7-23",
    "s3-y-7-24",
    "s3-y-7-25",
    "s3-y-7-26",
    "s3-y-7-27",
    "s3-y-7-28",
    "s3-y-7-29",
    "s3-y-7-30",
    "s3-y-7-32",
    "s3-y-7-33",
    "s3-y-7-34",
    "s3-y-7-35",
    "s3-y-7-36",
    "s3-y-7-37",
    "s3-y-7-38",
    "s3-y-7-39",
    "s3-y-7-40",
    "s3-y-7-41",
    "s3-y-7-42",
    "s3-y-7-43",
    "s3-y-8-6",
    "s3-y-8-7",
    "s3-y-8-8",
    "s3-y-8-9",
    "s3-y-8-10",
    "s3-y-8-11",
    "s3-y-8-12",
    "s3-y-8-13",
    "s3-y-8-14",
    "s3-y-8-15",
    "s3-y-8-16",
    "s3-y-8-17",
    "s3-y-8-19",
    "s3-y-8-20",
    "s3-y-8-21",
    "s3-y-8-22",
    "s3-y-8-23",
    "s3-y-8-24",
    "s3-y-8-25",
    "s3-y-8-26",
    "s3-y-8-27",
    "s3-y-8-28",
    "s3-y-8-29",
    "s3-y-8-30",
    "s3-y-8-32",
    "s3-y-8-33",
    "s3-y-8-34",
    "s3-y-8-35",
    "s3-y-8-36",
    "s3-y-8-37",
    "s3-y-8-38",
    "s3-y-8-39",
    "s3-y-8-40",
    "s3-y-8-41",
    "s3-y-8-42",
    "s3-y-8-43",
    "s3-y-9-6",
    "s3-y-9-7",
    "s3-y-9-8",
    "s3-y-9-9",
    "s3-y-9-10",
    "s3-y-9-11",
    "s3-y-9-12",
    "s3-y-9-13",
    "s3-y-9-14",
    "s3-y-9-15",
    "s3-y-9-16",
    "s3-y-9-17",
    "s3-y-9-19",
    "s3-y-9-20",
    "s3-y-9-21",
    "s3-y-9-22",
    "s3-y-9-23",
    "s3-y-9-24",
    "s3-y-9-25",
    "s3-y-9-26",
    "s3-y-9-27",
    "s3-y-9-28",
    "s3-y-9-29",
    "s3-y-9-30",
    "s3-y-9-32",
    "s3-y-9-33",
    "s3-y-9-34",
    "s3-y-9-35",
    "s3-y-9-36",
    "s3-y-9-37",
    "s3-y-9-38",
    "s3-y-9-39",
    "s3-y-9-40",
    "s3-y-9-41",
    "s3-y-9-42",
    "s3-y-9-43",
    "s3-y-10-6",
    "s3-y-10-7",
    "s3-y-10-8",
    "s3-y-10-9",
    "s3-y-10-10",
    "s3-y-10-11",
    "s3-y-10-12",
    "s3-y-10-13",
    "s3-y-10-14",
    "s3-y-10-15",
    "s3-y-10-16",
    "s3-y-10-17",
    "s3-y-10-19",
    "s3-y-10-20",
    "s3-y-10-21",
    "s3-y-10-22",
    "s3-y-10-23",
    "s3-y-10-24",
    "s3-y-10-25",
    "s3-y-10-26",
    "s3-y-10-27",
    "s3-y-10-28",
    "s3-y-10-29",
    "s3-y-10-30",
    "s3-y-10-32",
    "s3-y-10-33",
    "s3-y-10-34",
    "s3-y-10-35",
    "s3-y-10-36",
    "s3-y-10-37",
    "s3-y-10-38",
    "s3-y-10-39",
    "s3-y-10-40",
    "s3-y-10-41",
    "s3-y-10-42",
    "s3-y-10-43",
    "s3-y-11-6",
    "s3-y-11-7",
    "s3-y-11-8",
    "s3-y-11-9",
    "s3-y-11-10",
    "s3-y-11-11",
    "s3-y-11-12",
    "s3-y-11-13",
    "s3-y-11-14",
    "s3-y-11-15",
    "s3-y-11-16",
    "s3-y-11-17",
    "s3-y-11-19",
    "s3-y-11-20",
    "s3-y-11-21",
    "s3-y-11-22",
    "s3-y-11-23",
    "s3-y-11-24",
    "s3-y-11-25",
    "s3-y-11-26",
    "s3-y-11-27",
    "s3-y-11-28",
    "s3-y-11-29",
    "s3-y-11-30",
    "s3-y-11-32",
    "s3-y-11-33",
    "s3-y-11-34",
    "s3-y-11-35",
    "s3-y-11-36",
    "s3-y-11-37",
    "s3-y-11-38",
    "s3-y-11-39",
    "s3-y-11-40",
    "s3-y-11-41",
    "s3-y-11-42",
    "s3-y-11-43",
    "s3-y-12-8",
    "s3-y-12-9",
    "s3-y-12-10",
    "s3-y-12-11",
    "s3-y-12-12",
    "s3-y-12-13",
    "s3-y-12-14",
    "s3-y-12-15",
    "s3-y-12-16",
    "s3-y-12-17",
    "s3-y-12-19",
    "s3-y-12-20",
    "s3-y-12-21",
    "s3-y-12-22",
    "s3-y-12-23",
    "s3-y-12-24",
    "s3-y-12-25",
    "s3-y-12-26",
    "s3-y-12-27",
    "s3-y-12-28",
    "s3-y-12-29",
    "s3-y-12-30",
    "s3-y-12-32",
    "s3-y-12-33",
    "s3-y-12-34",
    "s3-y-12-35",
    "s3-y-12-36",
    "s3-y-12-37",
    "s3-y-12-38",
    "s3-y-12-39",
    "s3-y-12-40",
    "s3-y-12-41",
    "s3-y-13-1",
    "s3-y-13-2",
    "s3-y-13-3",
    "s3-y-13-4",
    "s3-y-13-45",
    "s3-y-13-46",
    "s3-y-13-47",
    "s3-y-13-48",
    "s3-y-13-8",
    "s3-y-13-9",
    "s3-y-13-10",
    "s3-y-13-11",
    "s3-y-13-12",
    "s3-y-13-13",
    "s3-y-13-14",
    "s3-y-13-15",
    "s3-y-13-16",
    "s3-y-13-17",
    "s3-y-13-19",
    "s3-y-13-20",
    "s3-y-13-21",
    "s3-y-13-22",
    "s3-y-13-23",
    "s3-y-13-24",
    "s3-y-13-25",
    "s3-y-13-26",
    "s3-y-13-27",
    "s3-y-13-28",
    "s3-y-13-29",
    "s3-y-13-30",
    "s3-y-13-32",
    "s3-y-13-33",
    "s3-y-13-34",
    "s3-y-13-35",
    "s3-y-13-36",
    "s3-y-13-37",
    "s3-y-13-38",
    "s3-y-13-39",
    "s3-y-13-40",
    "s3-y-13-41",
    "s3-y-14-45",
    "s3-y-14-46",
    "s3-y-14-47",
    "s3-y-14-48",
    "s3-y-14-1",
    "s3-y-14-2",
    "s3-y-14-3",
    "s3-y-14-4",
    "s3-y-14-8",
    "s3-y-14-9",
    "s3-y-14-10",
    "s3-y-14-11",
    "s3-y-14-12",
    "s3-y-14-13",
    "s3-y-14-14",
    "s3-y-14-15",
    "s3-y-14-16",
    "s3-y-14-17",
    "s3-y-14-19",
    "s3-y-14-20",
    "s3-y-14-21",
    "s3-y-14-22",
    "s3-y-14-23",
    "s3-y-14-24",
    "s3-y-14-25",
    "s3-y-14-26",
    "s3-y-14-27",
    "s3-y-14-28",
    "s3-y-14-29",
    "s3-y-14-30",
    "s3-y-14-32",
    "s3-y-14-33",
    "s3-y-14-34",
    "s3-y-14-35",
    "s3-y-14-36",
    "s3-y-14-37",
    "s3-y-14-38",
    "s3-y-14-39",
    "s3-y-14-40",
    "s3-y-14-41",
    "s3-y-15-45",
    "s3-y-15-46",
    "s3-y-15-47",
    "s3-y-15-48",
    "s3-y-15-1",
    "s3-y-15-2",
    "s3-y-15-3",
    "s3-y-15-4",
    "s3-y-16-1",
    "s3-y-16-2",
    "s3-y-16-3",
    "s3-y-16-4",
    "s3-y-16-10",
    "s3-y-16-11",
    "s3-y-16-12",
    "s3-y-16-13",
    "s3-y-16-14",
    "s3-y-16-15",
    "s3-y-16-16",
    "s3-y-16-20",
    "s3-y-16-21",
    "s3-y-16-22",
    "s3-y-16-23",
    "s3-y-16-24",
    "s3-y-16-25",
    "s3-y-16-26",
    "s3-y-16-27",
    "s3-y-16-28",
    "s3-y-16-33",
    "s3-y-16-34",
    "s3-y-16-35",
    "s3-y-16-36",
    "s3-y-16-37",
    "s3-y-16-38",
    "s3-y-16-39",
    "s3-y-16-45",
    "s3-y-16-46",
    "s3-y-16-47",
    "s3-y-16-48",
    "s3-y-17-1",
    "s3-y-17-2",
    "s3-y-17-3",
    "s3-y-17-4",
    "s3-y-17-10",
    "s3-y-17-11",
    "s3-y-17-12",
    "s3-y-17-13",
    "s3-y-17-14",
    "s3-y-17-15",
    "s3-y-17-16",
    "s3-y-17-20",
    "s3-y-17-21",
    "s3-y-17-22",
    "s3-y-17-23",
    "s3-y-17-24",
    "s3-y-17-25",
    "s3-y-17-26",
    "s3-y-17-27",
    "s3-y-17-28",
    "s3-y-17-33",
    "s3-y-17-34",
    "s3-y-17-35",
    "s3-y-17-36",
    "s3-y-17-37",
    "s3-y-17-38",
    "s3-y-17-39",
    "s3-y-17-45",
    "s3-y-17-46",
    "s3-y-17-47",
    "s3-y-17-48",
    "s3-y-18-1",
    "s3-y-18-2",
    "s3-y-18-3",
    "s3-y-18-4",
    "s3-y-18-5",
    "s3-y-18-9",
    "s3-y-18-10",
    "s3-y-18-11",
    "s3-y-18-12",
    "s3-y-18-13",
    "s3-y-18-14",
    "s3-y-18-15",
    "s3-y-18-16",
    "s3-y-18-20",
    "s3-y-18-21",
    "s3-y-18-22",
    "s3-y-18-23",
    "s3-y-18-24",
    "s3-y-18-25",
    "s3-y-18-26",
    "s3-y-18-27",
    "s3-y-18-28",
    "s3-y-18-29",
    "s3-y-18-33",
    "s3-y-18-34",
    "s3-y-18-35",
    "s3-y-18-36",
    "s3-y-18-37",
    "s3-y-18-38",
    "s3-y-18-39",
    "s3-y-18-40",
    "s3-y-18-44",
    "s3-y-18-45",
    "s3-y-18-46",
    "s3-y-18-47",
    "s3-y-18-48",
    "s3-y-19-1",
    "s3-y-19-2",
    "s3-y-19-3",
    "s3-y-19-4",
    "s3-y-19-5",
    "s3-y-19-6",
    "s3-y-19-8",
    "s3-y-19-9",
    "s3-y-19-10",
    "s3-y-19-11",
    "s3-y-19-12",
    "s3-y-19-13",
    "s3-y-19-14",
    "s3-y-19-15",
    "s3-y-19-16",
    "s3-y-19-17",
    "s3-y-19-19",
    "s3-y-19-20",
    "s3-y-19-21",
    "s3-y-19-22",
    "s3-y-19-23",
    "s3-y-19-24",
    "s3-y-19-25",
    "s3-y-19-26",
    "s3-y-19-27",
    "s3-y-19-28",
    "s3-y-19-29",
    "s3-y-19-30",
    "s3-y-19-32",
    "s3-y-19-33",
    "s3-y-19-34",
    "s3-y-19-35",
    "s3-y-19-36",
    "s3-y-19-37",
    "s3-y-19-38",
    "s3-y-19-39",
    "s3-y-19-40",
    "s3-y-19-41",
    "s3-y-19-43",
    "s3-y-19-44",
    "s3-y-19-45",
    "s3-y-19-46",
    "s3-y-19-47",
    "s3-y-19-48",
    "s3-y-20-1",
    "s3-y-20-2",
    "s3-y-20-3",
    "s3-y-20-4",
    "s3-y-20-5",
    "s3-y-20-6",
    "s3-y-20-8",
    "s3-y-20-9",
    "s3-y-20-10",
    "s3-y-20-11",
    "s3-y-20-12",
    "s3-y-20-13",
    "s3-y-20-14",
    "s3-y-20-15",
    "s3-y-20-16",
    "s3-y-20-17",
    "s3-y-20-19",
    "s3-y-20-20",
    "s3-y-20-21",
    "s3-y-20-22",
    "s3-y-20-23",
    "s3-y-20-24",
    "s3-y-20-25",
    "s3-y-20-26",
    "s3-y-20-27",
    "s3-y-20-28",
    "s3-y-20-29",
    "s3-y-20-30",
    "s3-y-20-32",
    "s3-y-20-33",
    "s3-y-20-34",
    "s3-y-20-35",
    "s3-y-20-36",
    "s3-y-20-37",
    "s3-y-20-38",
    "s3-y-20-39",
    "s3-y-20-40",
    "s3-y-20-41",
    "s3-y-20-43",
    "s3-y-20-44",
    "s3-y-20-45",
    "s3-y-20-46",
    "s3-y-20-47",
    "s3-y-20-48",
    "s3-y-21-1",
    "s3-y-21-2",
    "s3-y-21-3",
    "s3-y-21-4",
    "s3-y-21-5",
    "s3-y-21-6",
    "s3-y-21-8",
    "s3-y-21-9",
    "s3-y-21-10",
    "s3-y-21-11",
    "s3-y-21-12",
    "s3-y-21-13",
    "s3-y-21-14",
    "s3-y-21-15",
    "s3-y-21-16",
    "s3-y-21-17",
    "s3-y-21-19",
    "s3-y-21-20",
    "s3-y-21-21",
    "s3-y-21-22",
    "s3-y-21-23",
    "s3-y-21-24",
    "s3-y-21-25",
    "s3-y-21-26",
    "s3-y-21-27",
    "s3-y-21-28",
    "s3-y-21-29",
    "s3-y-21-30",
    "s3-y-21-32",
    "s3-y-21-33",
    "s3-y-21-34",
    "s3-y-21-35",
    "s3-y-21-36",
    "s3-y-21-37",
    "s3-y-21-38",
    "s3-y-21-39",
    "s3-y-21-40",
    "s3-y-21-41",
    "s3-y-21-43",
    "s3-y-21-44",
    "s3-y-21-45",
    "s3-y-21-46",
    "s3-y-21-47",
    "s3-y-21-48",
    "s3-y-22-1",
    "s3-y-22-2",
    "s3-y-22-3",
    "s3-y-22-4",
    "s3-y-22-5",
    "s3-y-22-6",
    "s3-y-22-8",
    "s3-y-22-9",
    "s3-y-22-10",
    "s3-y-22-11",
    "s3-y-22-12",
    "s3-y-22-13",
    "s3-y-22-14",
    "s3-y-22-15",
    "s3-y-22-16",
    "s3-y-22-17",
    "s3-y-22-19",
    "s3-y-22-20",
    "s3-y-22-21",
    "s3-y-22-22",
    "s3-y-22-23",
    "s3-y-22-24",
    "s3-y-22-25",
    "s3-y-22-26",
    "s3-y-22-27",
    "s3-y-22-28",
    "s3-y-22-29",
    "s3-y-22-30",
    "s3-y-22-32",
    "s3-y-22-33",
    "s3-y-22-34",
    "s3-y-22-35",
    "s3-y-22-36",
    "s3-y-22-37",
    "s3-y-22-38",
    "s3-y-22-39",
    "s3-y-22-40",
    "s3-y-22-41",
    "s3-y-22-43",
    "s3-y-22-44",
    "s3-y-22-45",
    "s3-y-22-46",
    "s3-y-22-47",
    "s3-y-22-48",
    "s3-y-23-1",
    "s3-y-23-2",
    "s3-y-23-3",
    "s3-y-23-4",
    "s3-y-23-5",
    "s3-y-23-6",
    "s3-y-23-8",
    "s3-y-23-9",
    "s3-y-23-10",
    "s3-y-23-11",
    "s3-y-23-12",
    "s3-y-23-13",
    "s3-y-23-14",
    "s3-y-23-15",
    "s3-y-23-16",
    "s3-y-23-17",
    "s3-y-23-19",
    "s3-y-23-20",
    "s3-y-23-21",
    "s3-y-23-22",
    "s3-y-23-23",
    "s3-y-23-24",
    "s3-y-23-25",
    "s3-y-23-26",
    "s3-y-23-27",
    "s3-y-23-28",
    "s3-y-23-29",
    "s3-y-23-30",
    "s3-y-23-32",
    "s3-y-23-33",
    "s3-y-23-34",
    "s3-y-23-35",
    "s3-y-23-36",
    "s3-y-23-37",
    "s3-y-23-38",
    "s3-y-23-39",
    "s3-y-23-40",
    "s3-y-23-41",
    "s3-y-23-43",
    "s3-y-23-44",
    "s3-y-23-45",
    "s3-y-23-46",
    "s3-y-23-47",
    "s3-y-23-48",
    "s3-y-24-1",
    "s3-y-24-2",
    "s3-y-24-3",
    "s3-y-24-4",
    "s3-y-24-5",
    "s3-y-24-6",
    "s3-y-24-8",
    "s3-y-24-9",
    "s3-y-24-10",
    "s3-y-24-11",
    "s3-y-24-12",
    "s3-y-24-13",
    "s3-y-24-14",
    "s3-y-24-15",
    "s3-y-24-16",
    "s3-y-24-17",
    "s3-y-24-19",
    "s3-y-24-20",
    "s3-y-24-21",
    "s3-y-24-22",
    "s3-y-24-23",
    "s3-y-24-24",
    "s3-y-24-25",
    "s3-y-24-26",
    "s3-y-24-27",
    "s3-y-24-28",
    "s3-y-24-29",
    "s3-y-24-30",
    "s3-y-24-32",
    "s3-y-24-33",
    "s3-y-24-34",
    "s3-y-24-35",
    "s3-y-24-36",
    "s3-y-24-37",
    "s3-y-24-38",
    "s3-y-24-39",
    "s3-y-24-40",
    "s3-y-24-41",
    "s3-y-24-43",
    "s3-y-24-44",
    "s3-y-24-45",
    "s3-y-24-46",
    "s3-y-24-47",
    "s3-y-24-48",
    "s3-y-25-1",
    "s3-y-25-2",
    "s3-y-25-3",
    "s3-y-25-4",
    "s3-y-25-5",
    "s3-y-25-6",
    "s3-y-25-8",
    "s3-y-25-9",
    "s3-y-25-10",
    "s3-y-25-11",
    "s3-y-25-12",
    "s3-y-25-13",
    "s3-y-25-14",
    "s3-y-25-15",
    "s3-y-25-16",
    "s3-y-25-17",
    "s3-y-25-19",
    "s3-y-25-20",
    "s3-y-25-21",
    "s3-y-25-22",
    "s3-y-25-23",
    "s3-y-25-24",
    "s3-y-25-25",
    "s3-y-25-26",
    "s3-y-25-27",
    "s3-y-25-28",
    "s3-y-25-29",
    "s3-y-25-30",
    "s3-y-25-32",
    "s3-y-25-33",
    "s3-y-25-34",
    "s3-y-25-35",
    "s3-y-25-36",
    "s3-y-25-37",
    "s3-y-25-38",
    "s3-y-25-39",
    "s3-y-25-40",
    "s3-y-25-41",
    "s3-y-25-43",
    "s3-y-25-44",
    "s3-y-25-45",
    "s3-y-25-46",
    "s3-y-25-47",
    "s3-y-25-48",
  ];

  // // ②保護者座席
  // const studios = [
  //   // 1部
  //   { studio_name: "木津川", seat_count: "70", class: "type_2" },
  //   { studio_name: "新田辺", seat_count: "88", class: "type_3" },
  //   { studio_name: "烏丸", seat_count: "26", class: "type_4" },
  //   { studio_name: "宇治", seat_count: "34", class: "type_5" },
  //   { studio_name: "丹波橋", seat_count: "68", class: "type_7" },
  //   { studio_name: "西大路御池", seat_count: "72", class: "type_8" },
  //   { studio_name: "山科", seat_count: "42", class: "type_9" },
  //   { studio_name: "桂", seat_count: "34", class: "type_10" },
  //   { studio_name: "出町柳", seat_count: "8", class: "type_11" },

  //   // 2部
  //   { studio_name: "天理", seat_count: "28", class: "type_2" },
  //   { studio_name: "大津京", seat_count: "12", class: "type_3" },
  //   { studio_name: "加古川", seat_count: "66", class: "type_4" },
  //   { studio_name: "西明石", seat_count: "18", class: "type_5" },
  //   { studio_name: "草津", seat_count: "34", class: "type_6" },
  //   { studio_name: "大和八木", seat_count: "28", class: "type_7" },
  //   { studio_name: "大宮", seat_count: "46", class: "type_8" },
  //   { studio_name: "学園前", seat_count: "74", class: "type_9" },
  //   { studio_name: "垂水", seat_count: "48", class: "type_10" },
  //   { studio_name: "西神中央", seat_count: "114", class: "type_11" },
  //   { studio_name: "東生駒", seat_count: "24", class: "type_12" },

  //   // 3部
  //   { studio_name: "福島", seat_count: "22", class: "type_13" },
  //   { studio_name: "三国ヶ丘", seat_count: "38", class: "type_2" },
  //   { studio_name: "布施", seat_count: "26", class: "type_3" },
  //   { studio_name: "豊中", seat_count: "10", class: "type_4" },
  //   { studio_name: "岸和田", seat_count: "46", class: "type_5" },
  //   { studio_name: "長岡京", seat_count: "60", class: "type_6" },
  //   { studio_name: "高槻", seat_count: "52", class: "type_6" },
  //   { studio_name: "池田", seat_count: "46", class: "type_7" },
  //   { studio_name: "江坂", seat_count: "36", class: "type_8" },
  //   { studio_name: "和泉", seat_count: "26", class: "type_9" },
  //   { studio_name: "栂・美木多", seat_count: "22", class: "type_10" },
  //   { studio_name: "楠葉", seat_count: "52", class: "type_11" },
  //   { studio_name: "天王寺", seat_count: "18", class: "type_12" },
  //   { studio_name: "泉南", seat_count: "16", class: "type_13" },
  //   { studio_name: "八尾", seat_count: "14", class: "type_14" },
  // ];

  // 1部
  const sec1_seat_data = SECTION1_SEATS;

  // 2部
  const sec2_seat_data = SECTION2_SEATS;

  // 3部
  const sec3_seat_data = SECTION3_SEATS;

  useEffect(() => {
    let seatNumber = 1;
    const allPaths = document.querySelectorAll(".seat-item");
    allPaths.forEach((path) => {
      path.setAttribute("data-seatno", seatNumber++);
    });

    // 1部
    // ①出演生徒座席
    allPaths.forEach((path) => {
      if (path.getAttribute("data-seatno") <= 156) {
        path.classList.add("type_1");
      }
    });

    // 出力
    for (let i = 0; i < sec1_seat_data.length; i++) {
      // sec1_seat_dataの処理をここに書く
      const seatElement = document.querySelector(
        `[data-seatno="${sec1_seat_data[i].dataseat}"]`
      );
      if (seatElement) {
        seatElement.classList.add(sec1_seat_data[i].class);
        seatElement.dataset.studioname = sec1_seat_data[i].studio;
        seatElement.dataset.name = sec1_seat_data[i].name;
      }
    }
    for (let i = 0; i < sec2_seat_data.length; i++) {
      // sec2_seat_dataの処理をここに書く
      const seatElement = document.querySelector(
        `[data-seatno="${sec2_seat_data[i].dataseat}"]`
      );
      if (seatElement) {
        seatElement.classList.add(sec2_seat_data[i].class);
        seatElement.dataset.studioname = sec2_seat_data[i].studio;
        seatElement.dataset.name = sec2_seat_data[i].name;
      }
    }
    for (let i = 0; i < sec3_seat_data.length; i++) {
      // sec3_seat_dataの処理をここに書く
      const seatElement = document.querySelector(
        `[data-seatno="${sec3_seat_data[i].dataseat}"]`
      );
      if (seatElement) {
        seatElement.classList.add(sec3_seat_data[i].class);
        seatElement.dataset.studioname = sec3_seat_data[i].studio;
        seatElement.dataset.name = sec3_seat_data[i].name;
      }
    }
  }, []);

  useEffect(() => {
    const studioSeatCounts = document.querySelectorAll(".studio_seat_count");
    const studioNames = document.querySelectorAll(".studio_name");

    studioNames.forEach((studioNameElement) => {
      const studioName = studioNameElement.textContent;
      const matchingSeats = document.querySelectorAll(
        `[data-studioname="${studioName}"]`
      ).length;
      const seatCountElement = studioNameElement.nextElementSibling;
      if (seatCountElement) {
        seatCountElement.textContent = matchingSeats;
      }
    });
  }, []);

  const [info, setInfo] = useState({
    seatNo: "-",
    studioName: "-",
    name: "-",
  });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [isSvgHovered, setIsSvgHovered] = useState(false);

  useEffect(() => {
    // マウスの移動を追跡
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.pageX, y: e.pageY }); // 修正点：clientX/YからpageX/Yに変更
    };
    window.addEventListener("mousemove", handleMouseMove);

    // SVGのパス要素にイベントリスナーを追加
    const paths = document.querySelectorAll("path");
    const handlePathMouseOver = (e) => {
      const path = e.currentTarget;
      const seatNo = path.getAttribute("data-seatno") || "-";
      const studioName = path.getAttribute("data-studioname") || "-";
      const name = path.getAttribute("data-name") || "-";
      setInfo({ seatNo, studioName, name });
    };
    const handlePathMouseOut = () => {
      setInfo({
        seatNo: "-",
        studioName: "-",
        name: "-",
      });
    };
    paths.forEach((path) => {
      path.addEventListener("mouseover", handlePathMouseOver);
      path.addEventListener("mouseout", handlePathMouseOut);
    });

    // すべてのSVG要素にイベントリスナーを追加
    const svgElements = document.querySelectorAll("svg");
    const handleSvgMouseOver = () => {
      setIsSvgHovered(true);
    };
    const handleSvgMouseOut = () => {
      setIsSvgHovered(false);
    };
    svgElements.forEach((svgElement) => {
      svgElement.addEventListener("mouseover", handleSvgMouseOver);
      svgElement.addEventListener("mouseout", handleSvgMouseOut);
    });

    // マウスストーカーのターゲット要素にイベントリスナーを追加
    const targets = document.querySelectorAll(".stkr-target");
    const handleTargetMouseOver = () => {
      setScale(2);
    };
    const handleTargetMouseOut = () => {
      setScale(1);
    };
    targets.forEach((target) => {
      target.addEventListener("mouseover", handleTargetMouseOver);
      target.addEventListener("mouseout", handleTargetMouseOut);
    });

    // クリーンアップ
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      paths.forEach((path) => {
        path.removeEventListener("mouseover", handlePathMouseOver);
        path.removeEventListener("mouseout", handlePathMouseOut);
      });
      svgElements.forEach((svgElement) => {
        svgElement.removeEventListener("mouseover", handleSvgMouseOver);
        svgElement.removeEventListener("mouseout", handleSvgMouseOut);
      });
      targets.forEach((target) => {
        target.removeEventListener("mouseover", handleTargetMouseOver);
        target.removeEventListener("mouseout", handleTargetMouseOut);
      });
    };
  }, []);

  const offsetX = 10; // マウスカーソルの右に10px
  const offsetY = -10; // マウスカーソルの上に10px

  return (
    <>
      <ul className="studio_seat_count_list">
        <li className="full">
          <p>1部</p>
        </li>
        <li>
          <p className="studio_name">生徒席1</p>
          <p className="studio_seat_count">
            <span></span>
          </p>
        </li>
        <li>
          <p className="studio_name">木津川</p>
          <p className="studio_seat_count"></p>
          <span>68</span>
        </li>
        <li>
          <p className="studio_name">新田辺</p>
          <p className="studio_seat_count"></p>
          <span>88</span>
        </li>
        <li>
          <p className="studio_name">烏丸</p>
          <p className="studio_seat_count"></p>
          <span>24</span>
        </li>
        <li>
          <p className="studio_name">宇治</p>
          <p className="studio_seat_count"></p>
          <span>34</span>
        </li>
        <li>
          <p className="studio_name">丹波橋</p>
          <p className="studio_seat_count"></p>
          <span>68</span>
        </li>
        <li>
          <p className="studio_name">西大路御池</p>
          <p className="studio_seat_count"></p>
          <span>72</span>
        </li>
        <li>
          <p className="studio_name">山科</p>
          <p className="studio_seat_count"></p>
          <span>42</span>
        </li>
        <li>
          <p className="studio_name">桂</p>
          <p className="studio_seat_count"></p>
          <span>34</span>
        </li>
        <li>
          <p className="studio_name">出町柳</p>
          <p className="studio_seat_count"></p>
          <span>8</span>
        </li>
        <li>
          <p className="studio_name">京都選抜</p>
          <p className="studio_seat_count"></p>
          <span>2</span>
        </li>
        <li>
          <p className="studio_name">SLASH選抜1</p>
          <p className="studio_seat_count"></p>
          <span>20</span>
        </li>
        <li>
          <p className="studio_name">一般1</p>
          <p className="studio_seat_count"></p>
          <span>0</span>
        </li>
        <li>
          <p className="studio_name">関係者1</p>
          <p className="studio_seat_count"></p>
          <span>0</span>
        </li>

        <li className="full">
          <p>2部</p>
        </li>
        <li>
          <p className="studio_name">生徒席2</p>
          <p className="studio_seat_count"></p>
        </li>
        <li>
          <p className="studio_name">天理</p>
          <p className="studio_seat_count"></p>
          <span>28</span>
        </li>
        <li>
          <p className="studio_name">大津京</p>
          <p className="studio_seat_count"></p>
          <span>12</span>
        </li>
        <li>
          <p className="studio_name">加古川</p>
          <p className="studio_seat_count"></p>
          <span>66</span>
        </li>
        <li>
          <p className="studio_name">西明石</p>
          <p className="studio_seat_count"></p>
          <span>18</span>
        </li>
        <li>
          <p className="studio_name">草津</p>
          <p className="studio_seat_count"></p>
          <span>34</span>
        </li>
        <li>
          <p className="studio_name">大和八木</p>
          <p className="studio_seat_count"></p>
          <span>28</span>
        </li>
        <li>
          <p className="studio_name">大宮</p>
          <p className="studio_seat_count"></p>
          <span>46</span>
        </li>
        <li>
          <p className="studio_name">学園前</p>
          <p className="studio_seat_count"></p>
          <span>74</span>
        </li>
        <li>
          <p className="studio_name">垂水</p>
          <p className="studio_seat_count"></p>
          <span>48</span>
        </li>
        <li>
          <p className="studio_name">西神中央</p>
          <p className="studio_seat_count"></p>
          <span>114</span>
        </li>
        <li>
          <p className="studio_name">東生駒</p>
          <p className="studio_seat_count"></p>
          <span>24</span>
        </li>
        <li>
          <p className="studio_name">武庫川 初級</p>
          <p className="studio_seat_count"></p>
          <span>12</span>
        </li>
        <li>
          <p className="studio_name">兵庫選抜</p>
          <p className="studio_seat_count"></p>
          <span>2</span>
        </li>
        <li>
          <p className="studio_name">奈良選抜</p>
          <p className="studio_seat_count"></p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">SLASH選抜2</p>
          <p className="studio_seat_count"></p>
          <span>20</span>
        </li>
        <li>
          <p className="studio_name">一般2</p>
          <p className="studio_seat_count"></p>
          <span>0</span>
        </li>
        <li>
          <p className="studio_name">関係者2</p>
          <p className="studio_seat_count"></p>
          <span>0</span>
        </li>

        <li className="full">
          <p>3部</p>
        </li>
        <li>
          <p className="studio_name">生徒席3</p>
          <p className="studio_seat_count"></p>
        </li>
        <li>
          <p className="studio_name">福島</p>
          <p className="studio_seat_count"></p>
          <span>22</span>
        </li>
        <li>
          <p className="studio_name">三国ヶ丘</p>
          <p className="studio_seat_count"></p>
          <span>40</span>
        </li>
        <li>
          <p className="studio_name">布施</p>
          <p className="studio_seat_count"></p>
          <span>30</span>
        </li>
        <li>
          <p className="studio_name">豊中</p>
          <p className="studio_seat_count"></p>
          <span>10</span>
        </li>
        <li>
          <p className="studio_name">岸和田</p>
          <p className="studio_seat_count"></p>
          <span>42</span>
        </li>
        <li>
          <p className="studio_name">長岡京</p>
          <p className="studio_seat_count"></p>
          <span>62</span>
        </li>
        <li>
          <p className="studio_name">高槻</p>
          <p className="studio_seat_count"></p>
          <span>46</span>
        </li>
        <li>
          <p className="studio_name">池田</p>
          <p className="studio_seat_count"></p>
          <span>40</span>
        </li>
        <li>
          <p className="studio_name">江坂</p>
          <p className="studio_seat_count"></p>
          <span>36</span>
        </li>
        <li>
          <p className="studio_name">和泉</p>
          <p className="studio_seat_count"></p>
          <span>26</span>
        </li>
        <li>
          <p className="studio_name">栂・美木多</p>
          <p className="studio_seat_count"></p>
          <span>22</span>
        </li>
        <li>
          <p className="studio_name">楠葉</p>
          <p className="studio_seat_count"></p>
          <span>48</span>
        </li>
        <li>
          <p className="studio_name">天王寺</p>
          <p className="studio_seat_count"></p>
          <span>18</span>
        </li>
        <li>
          <p className="studio_name">泉南</p>
          <p className="studio_seat_count"></p>
          <span>16</span>
        </li>
        <li>
          <p className="studio_name">八尾</p>
          <p className="studio_seat_count"></p>
          <span>12</span>
        </li>
        <li>
          <p className="studio_name">長田</p>
          <p className="studio_seat_count"></p>
          <span>14</span>
        </li>
        <li>
          <p className="studio_name">枚方</p>
          <p className="studio_seat_count"></p>
          <span>2</span>
        </li>
        <li>
          <p className="studio_name">武庫川 小学生以下</p>
          <p className="studio_seat_count"></p>
          <span>6</span>
        </li>
        <li>
          <p className="studio_name">大阪選抜</p>
          <p className="studio_seat_count"></p>
          <span>4</span>
        </li>
        <li>
          <p className="studio_name">SLASH選抜3</p>
          <p className="studio_seat_count"></p>
          <span>20</span>
        </li>
        <li>
          <p className="studio_name">一般3</p>
          <p className="studio_seat_count"></p>
          <span>0</span>
        </li>
        <li>
          <p className="studio_name">関係者3</p>
          <p className="studio_seat_count"></p>
          <span>0</span>
        </li>
      </ul>

      <div
        id="stkr"
        style={{
          position: "absolute",
          left: mousePosition.x + offsetX,
          top: mousePosition.y + offsetY,
          transform: `scale(${scale})`,
          visibility: isSvgHovered ? "visible" : "hidden",
          pointerEvents: "none",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <span>席番 </span>
        {info.seatNo}
        <br />
        <span>スタジオ </span>
        {info.studioName}
        <br />
        <span>名前 </span>
        {info.name}
      </div>

      {/* 1部 */}
      <div className="section">
        <h2>1部</h2>
        {/* 左カラム */}
        <div className="seat">
          <div className="DIV_IMG">
            <SeatSection1Svg />
          </div>

          {/* 右カラム */}
          <div className="seatlistArea">
            <div className="seatList">
              <ul className="seatlist_section_1">
                <li>id</li>
                <li>seat_no</li>
                <li>name</li>
                <li>studio</li>
                {/*  */}
                {sec1_seat_data
                  // .filter((seat) => seat.dataseat > 153)
                  .map((seat, index) => (
                    <React.Fragment key={index}>
                      <li>{index + 1}</li>
                      <li>{seat.data_seatno}</li>
                      <li>{seat.name}</li>
                      <li>{seat.studio}</li>
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* 2部 */}
      <div className="section">
        <h2>2部</h2>
        {/* 左カラム */}
        <div className="seat">
          <div className="DIV_IMG">
            <SeatSection2Svg />
          </div>

          {/* 右カラム */}
          <div className="seatlistArea">
            <div className="seatList">
              <ul className="seatlist_section_2">
                <li>id</li>
                <li>seat_no</li>
                <li>name</li>
                <li>studio</li>
                {/*  */}
                {sec2_seat_data
                  // .filter((seat) => seat.dataseat > 1054)
                  .map((seat, index) => (
                    <React.Fragment key={index}>
                      <li>{index + 1}</li>
                      <li>{seat.data_seatno}</li>
                      <li>{seat.name}</li>
                      <li>{seat.studio}</li>
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* 3部 */}
      <div className="section">
        <h2>3部</h2>
        {/* 左カラム */}
        <div className="seat">
          <div className="DIV_IMG">
            <SeatSection3Svg />
          </div>

          {/* 右カラム */}
          <div className="seatlistArea">
            <div className="seatList">
              <ul className="seatlist_section_3">
                <li>id</li>
                <li>seat_no</li>
                <li>name</li>
                <li>studio</li>
                {/*  */}
                {sec3_seat_data
                  // .filter((seat) => seat.dataseat > 1952)
                  .map((seat, index) => (
                    <React.Fragment key={index}>
                      <li>{index + 1}</li>
                      <li>{seat.data_seatno}</li>
                      <li>{seat.name}</li>
                      <li>{seat.studio}</li>
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeatPage;
