import React, { useState, useEffect, useRef } from "react";
import { Grid, Input, Icon, Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import { useLoading } from "../../contexts/LoadingContext";
import "../../styles/scss/orderList.scss";

function OrderListPage() {
  useLoginCheck();
  const { setIsLoading } = useLoading();
  const [orders, setOrders] = useState([]); // オーダー
  const [filteredOrders, setFilteredOrders] = useState([]); // 絞り込んだオーダー
  const [searchKeyword, setSearchKeyword] = useState(""); // 検索ワード
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  }); // ソート
  const searchInputRef = useRef(null);
  const [targetOrderId, setTargetOrderId] = useState(null); // 編集対象のオーダーID
  const [yamatoTrackingNumbers, setYamatoTrackingNumbers] = useState([]);
  const [yubinTrackingNumbers, setYubinTrackingNumbers] = useState([]);
  // const [targetTrackingNumber, setTargetTrackingNumber] = useState(""); // 編集対象の追跡番号
  const [shippedAnimeOn, setShippedAnimeOn] = useState("");
  const [trackingAnimeOn, setTrackingAnimeOn] = useState("");

  // 注文一覧の取得
  const fetchOrdersData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_orders/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();

      console.log(data);

      setOrders(data);
      setFilteredOrders(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
    setIsLoading(false);
  };

  // 初期表示時に注文のリストを取得
  useEffect(() => {
    fetchOrdersData();
  }, []);

  // ソート
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedOrders = React.useMemo(() => {
    let sortableOrders = [...filteredOrders];
    if (sortConfig.key !== null) {
      sortableOrders.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // itemsのソート処理
        if (sortConfig.key === "items") {
          aValue = a.items.map((item) => item.product_name).join(", ");
          bValue = b.items.map((item) => item.product_name).join(", ");
        }

        // tracking_numberのソート処理
        if (sortConfig.key === "tracking_number") {
          // aのtracking_numberの値を取得
          if (a.tracking_number) {
            const yamatoA = a.tracking_number.yamato
              ? a.tracking_number.yamato.join(", ")
              : "";
            const yubinA = a.tracking_number.yubin
              ? a.tracking_number.yubin.join(", ")
              : "";
            aValue = yamatoA + yubinA;
          } else {
            aValue = "未登録";
          }

          // bのtracking_numberの値を取得
          if (b.tracking_number) {
            const yamatoB = b.tracking_number.yamato
              ? b.tracking_number.yamato.join(", ")
              : "";
            const yubinB = b.tracking_number.yubin
              ? b.tracking_number.yubin.join(", ")
              : "";
            bValue = yamatoB + yubinB;
          } else {
            bValue = "未登録";
          }
        }

        // ソート比較
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableOrders;
  }, [filteredOrders, sortConfig]);

  // 検索
  const handleSearch = (event) => {
    let keyword = searchKeyword;

    if (event.type === "click" || event.key === "Enter") {
      const filtered = orders.filter((order) => {
        // tracking_numberの検索を関数化
        const trackingNumberMatches = () => {
          if (order.tracking_number) {
            // yamatoとyubinの中を検索
            const yamatoMatches =
              order.tracking_number.yamato &&
              order.tracking_number.yamato.some((num) => num.includes(keyword));
            const yubinMatches =
              order.tracking_number.yubin &&
              order.tracking_number.yubin.some((num) => num.includes(keyword));
            return yamatoMatches || yubinMatches;
          } else {
            // tracking_numberがnullの場合
            return "未登録".includes(keyword);
          }
        };

        return (
          (order.id && order.id.toString().includes(keyword)) ||
          (order.customer_id &&
            order.customer_id.toString().includes(keyword)) ||
          (order.name && order.name.includes(keyword)) ||
          (order.tel && order.tel.includes(keyword)) ||
          (order.mail && order.mail.includes(keyword)) ||
          (order.postcode && order.postcode.includes(keyword)) ||
          (order.address && order.address.includes(keyword)) ||
          (order.items &&
            order.items.some((item) => item.product_name.includes(keyword))) ||
          (order.total_amount &&
            order.total_amount.toString().includes(keyword)) ||
          (order.stripe_customer_id &&
            order.stripe_customer_id.includes(keyword)) ||
          trackingNumberMatches()
        );
      });
      setFilteredOrders(filtered);
    }
  };

  // 編集開始時に追跡番号を初期化
  useEffect(() => {
    if (targetOrderId !== null) {
      const order = orders.find((order) => order.id === targetOrderId);
      if (order) {
        const numberOfItems = order.items.reduce(
          (sum, item) => sum + item.quantity,
          0
        );
        const yamatoCount = Math.floor(numberOfItems / 2);
        const yubinCount = numberOfItems % 2;

        let initialYamatoNumbers = Array(yamatoCount).fill("");
        let initialYubinNumbers = Array(yubinCount).fill("");

        if (order.tracking_number) {
          if (order.tracking_number.yamato) {
            initialYamatoNumbers = order.tracking_number.yamato;
            if (initialYamatoNumbers.length < yamatoCount) {
              initialYamatoNumbers = initialYamatoNumbers.concat(
                Array(yamatoCount - initialYamatoNumbers.length).fill("")
              );
            }
          }
          if (order.tracking_number.yubin) {
            initialYubinNumbers = order.tracking_number.yubin;
            if (initialYubinNumbers.length < yubinCount) {
              initialYubinNumbers = initialYubinNumbers.concat(
                Array(yubinCount - initialYubinNumbers.length).fill("")
              );
            }
          }
        }

        setYamatoTrackingNumbers(initialYamatoNumbers);
        setYubinTrackingNumbers(initialYubinNumbers);
      }
    }
  }, [targetOrderId]);

  // 追跡番号の保存関数
  const saveTrackingNumber = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_tracking_number/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: targetOrderId,
          tracking_number: {
            yamato: yamatoTrackingNumbers,
            yubin: yubinTrackingNumbers,
          },
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      setTrackingAnimeOn("on");

      const updatedOrders = orders.map((order) => {
        if (order.id === targetOrderId) {
          return {
            ...order,
            tracking_number: {
              yamato: yamatoTrackingNumbers,
              yubin: yubinTrackingNumbers,
            },
          };
        }
        return order;
      });

      setOrders(updatedOrders);
      setFilteredOrders(updatedOrders);
      setTargetOrderId(null);
      setYamatoTrackingNumbers([]);
      setYubinTrackingNumbers([]);
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 発送済フラグの保存
  const changeShippedFlg = async (orderId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/change_shipped_flg/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: orderId,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      setShippedAnimeOn("on");

      const updatedOrders = orders.map((order) => {
        if (order.id === orderId) {
          return {
            ...order,
            shipped_flg: order.shipped_flg !== true,
          };
        }
        return order;
      });

      setOrders(updatedOrders);
      setFilteredOrders(updatedOrders);
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <>
      <p className={`save-anime ${shippedAnimeOn}`}>発送済フラグを保存</p>
      <p className={`save-anime ${trackingAnimeOn}`}>追跡番号を保存</p>
      <Grid>
        <h1>注文一覧</h1>
        <Input
          icon={{
            name: "search",
            circular: true,
            link: true,
            onClick: handleSearch,
          }}
          placeholder="Search..."
          value={searchKeyword}
          ref={searchInputRef}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          onKeyDown={handleSearch}
        />
      </Grid>

      <Grid>
        <Grid.Column width={16}>
          <ul className="list-grid list-header">
            <li className="cell" onClick={() => handleSort("id")}>
              ID
              {sortConfig.key === "id" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "id" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("customer_id")}>
              顧客ID
              {sortConfig.key === "customer_id" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "customer_id" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("name")}>
              名前
              {sortConfig.key === "name" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "name" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("tel")}>
              電話番号
              {sortConfig.key === "tel" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "tel" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("mail")}>
              メールアドレス
              {sortConfig.key === "mail" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "mail" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("postcode")}>
              郵便番号
              {sortConfig.key === "postcode" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "postcode" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("address")}>
              住所
              {sortConfig.key === "address" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "address" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("items")}>
              注文内容
              {sortConfig.key === "items" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "items" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("total_amount")}>
              金額
              {sortConfig.key === "total_amount" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "total_amount" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("tracking_number")}>
              追跡番号
              {sortConfig.key === "tracking_number" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "tracking_number" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell"></li>
            <li className="cell" onClick={() => handleSort("shipped_flg")}>
              発送済
              {sortConfig.key === "shipped_flg" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "shipped_flg" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
          </ul>

          {sortedOrders.map((order) => (
            <ul
              key={order.id}
              className={`list-grid ${targetOrderId === order.id ? "on" : ""}`}
            >
              <li className="cell">{order.id}</li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(order.customer_id);
                }}
              >
                {order.customer_id}
              </li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(order.name);
                }}
              >
                {order.name}
              </li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(order.tel);
                }}
              >
                {order.tel}
              </li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(order.mail);
                }}
              >
                {order.mail}
              </li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(order.postcode);
                }}
              >
                {order.postcode}
              </li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(order.address);
                }}
              >
                {order.address}
              </li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(
                    order.items.map((item) => item.product_name).join("\n")
                  );
                }}
              >
                {order.items.map((item) => item.product_name).join("\n")}
              </li>
              <li
                className="cell copy"
                onClick={() => {
                  navigator.clipboard.writeText(order.total_amount);
                }}
              >
                ¥{order.total_amount.toLocaleString()}
              </li>
              <li
                className="cell tracking"
                onClick={() => {
                  if (targetOrderId !== order.id) {
                    setTargetOrderId(order.id);
                  }
                }}
              >
                {targetOrderId === order.id ? (
                  <>
                    {yamatoTrackingNumbers.map((value, index) => (
                      <div key={`yamato-${index}`}>
                        ヤマト:
                        <input
                          type="text"
                          value={value}
                          onChange={(e) => {
                            const newValues = [...yamatoTrackingNumbers];
                            newValues[index] = e.target.value;
                            setYamatoTrackingNumbers(newValues);
                          }}
                          placeholder="ヤマト追跡番号を入力"
                        />
                      </div>
                    ))}

                    {yubinTrackingNumbers.map((value, index) => (
                      <div key={`yubin-${index}`}>
                        ゆうパック:
                        <input
                          type="text"
                          value={value}
                          onChange={(e) => {
                            const newValues = [...yubinTrackingNumbers];
                            newValues[index] = e.target.value;
                            setYubinTrackingNumbers(newValues);
                          }}
                          placeholder="ゆうパック追跡番号を入力"
                        />
                      </div>
                    ))}
                  </>
                ) : order.tracking_number ? (
                  <>
                    {order.tracking_number.yamato &&
                      order.tracking_number.yamato.map(
                        (num) => `ヤマト: ${num} \n`
                      )}
                    {order.tracking_number.yubin &&
                      order.tracking_number.yubin.map(
                        (num) => `ゆうパック: ${num} \n`
                      )}
                  </>
                ) : (
                  "未登録"
                )}
              </li>
              <li className="cell">
                {targetOrderId === order.id && (
                  <button
                    onClick={() => {
                      setTrackingAnimeOn("");
                      saveTrackingNumber();
                    }}
                  >
                    登録
                  </button>
                )}
              </li>
              <li className="cell">
                <input
                  type="checkbox"
                  checked={order.shipped_flg}
                  onChange={() => {
                    setShippedAnimeOn("");
                    changeShippedFlg(order.id);
                  }}
                />
              </li>
            </ul>
          ))}
          <p>
            {sortedOrders.filter((order) => !order.shipped_flg).length > 0 &&
              `未処理: ${
                sortedOrders.filter((order) => !order.shipped_flg).length
              }`}
          </p>
        </Grid.Column>
      </Grid>
    </>
  );
}

export default OrderListPage;
