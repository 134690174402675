import React, { useState, useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../../../hooks/useLoginCheck";

import "../../../../styles/scss/recital2025SpecialFee.scss";

function SpecialFeePage() {
  useLoginCheck();

  const [salaryList, setSalaryList] = useState([]);
  const [originalSalaryList, setOriginalSalaryList] = useState([]);
  const [message, setMessage] = useState("");

  // 給与の取得
  const fetchSpecialFeesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_recital2025_special_fees/`
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setSalaryList(data);
      setOriginalSalaryList(data); // 元のデータを保存
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 入力フォーム変更
  const handleInputChange = (e, id, field) => {
    let { value } = e.target;
    // 半角数値と空文字以外を除外
    if (value !== "" && !/^\d+$/.test(value)) {
      return;
    }
    // 数値に変換
    value = Number(value);
    setSalaryList((prevSalaryList) =>
      prevSalaryList.map((item) =>
        item.instructor_id === id ? { ...item, [field]: value } : item
      )
    );
  };

  // テキストエリア変更
  const handleTextareaChange = (e, instructor_id, field) => {
    const { value } = e.target;
    setSalaryList((prevSalaryList) =>
      prevSalaryList.map((item) =>
        item.instructor_id === instructor_id
          ? { ...item, [field]: value }
          : item
      )
    );
  };

  // データベースを更新する関数
  const updateDatabase = async (
    instructor_id,
    field,
    value,
    instructor_name
  ) => {
    // メッセージ削除
    setMessage("");

    // 元の値と比較
    const originalValue = originalSalaryList.find(
      (item) => item.instructor_id === instructor_id
    )[field];

    if (value == originalValue) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/update_recital2025_special_fee/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ instructor_id, field, value }),
        }
      );
      if (!response.ok) {
        throw new Error("データベースの更新に失敗しました。");
      }

      // originalSalaryListを更新
      setOriginalSalaryList((prevOriginalSalaryList) =>
        prevOriginalSalaryList.map((item) =>
          item.instructor_id === instructor_id
            ? { ...item, [field]: value }
            : item
        )
      );

      setMessage(instructor_name);
      console.log("データベースが正常に更新されました。");
    } catch (error) {
      alert("データベースの更新中にエラーが発生しました");
      console.error("データベースの更新中にエラーが発生しました:", error);
    }
  };

  // 座席データの取得
  const fetchSeatsData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_seats_json/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    fetchSpecialFeesData();
    fetchSeatsData();
  }, []);

  return (
    <>
      {/* メッセージ */}
      <p
        class={`${message ? "save-anime on" : "save-anime"}`}
        style={{ display: message ? "block" : "none" }}
      >
        保存{message && `<${message}>`}
      </p>

      {/* イントラ名 */}
      <h2>25発表会 給与</h2>
      <div className="graph">
        <ul>
          <li>イントラ名</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>{intr.instructor_name}</li>
            ))}
        </ul>
        <ul>
          <li>クラスナンバー出演生徒数</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>
                <input
                  type="tel"
                  className="price"
                  value={intr.number_fee}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "number_fee")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "number_fee",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />{" "}
                *{" "}
                <input
                  type="tel"
                  className="count"
                  placeholder=""
                  value={intr.number}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "number")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "number",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />
                <span className="total">{intr.number_fee * intr.number}円</span>
                <textarea
                  onChange={(e) =>
                    handleTextareaChange(e, intr.instructor_id, "number_memo")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "number_memo",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                >
                  {intr.number_memo}
                </textarea>
              </li>
            ))}
        </ul>
        <ul>
          <li>イントラナンバー参加</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>
                <input
                  type="tel"
                  className="price"
                  value={intr.intr_number_fee}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "intr_number_fee")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "intr_number_fee",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />{" "}
                *{" "}
                <input
                  type="tel"
                  className="count"
                  placeholder=""
                  value={intr.intr_number}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "intr_number")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "intr_number",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />
                <span className="total">
                  {intr.intr_number_fee * intr.intr_number}円
                </span>
                <textarea
                  id=""
                  onChange={(e) =>
                    handleTextareaChange(
                      e,
                      intr.instructor_id,
                      "intr_number_memo"
                    )
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "intr_number_memo",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                >
                  {intr.intr_number_memo}
                </textarea>
              </li>
            ))}
        </ul>
        <ul>
          <li>各府県ピックアップディレクター</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>
                <input
                  type="tel"
                  className="price"
                  value={intr.pickup_fee}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "pickup_fee")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "pickup_fee",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />{" "}
                *{" "}
                <input
                  type="tel"
                  className="count"
                  placeholder=""
                  value={intr.pickup}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "pickup")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "pickup",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />
                <span className="total">{intr.pickup_fee * intr.pickup}円</span>
                <textarea
                  id=""
                  onChange={(e) =>
                    handleTextareaChange(e, intr.instructor_id, "pickup_memo")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "pickup_memo",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                >
                  {intr.pickup_memo}
                </textarea>
              </li>
            ))}
        </ul>
        <ul>
          <li>各交通費</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>
                <input
                  type="tel"
                  className="price"
                  value={intr.trainfare_fee}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "trainfare_fee")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "trainfare_fee",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />{" "}
                *{" "}
                <input
                  type="tel"
                  className="count"
                  placeholder=""
                  value={intr.trainfare}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "trainfare")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "trainfare",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />
                <span className="total">
                  {intr.trainfare_fee * intr.trainfare}円
                </span>
                <textarea
                  id=""
                  onChange={(e) =>
                    handleTextareaChange(
                      e,
                      intr.instructor_id,
                      "trainfare_memo"
                    )
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "trainfare_memo",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                >
                  {intr.trainfare_memo}
                </textarea>
              </li>
            ))}
        </ul>
        <ul>
          <li>11/2 撮影スタッフ</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>
                <input
                  type="tel"
                  className="price"
                  value={intr.shooting_fee}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "shooting_fee")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "shooting_fee",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />{" "}
                *{" "}
                <input
                  type="tel"
                  className="count"
                  placeholder=""
                  value={intr.shooting}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "shooting")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "shooting",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />
                <span className="total">
                  {intr.shooting_fee * intr.shooting}円
                </span>
                <textarea
                  id=""
                  onChange={(e) =>
                    handleTextareaChange(e, intr.instructor_id, "shooting_memo")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "shooting_memo",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                >
                  {intr.shooting_memo}
                </textarea>
              </li>
            ))}
        </ul>
        <ul>
          <li>当日スタッフ</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>
                <input
                  type="tel"
                  className="price"
                  value={intr.staff_fee}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "staff_fee")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "staff_fee",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />{" "}
                *{" "}
                <input
                  type="tel"
                  className="count"
                  placeholder=""
                  value={intr.staff}
                  onChange={(e) =>
                    handleInputChange(e, intr.instructor_id, "staff")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "staff",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                />
                <span className="total">{intr.staff_fee * intr.staff}円</span>
                <textarea
                  id=""
                  onChange={(e) =>
                    handleTextareaChange(e, intr.instructor_id, "staff_memo")
                  }
                  onBlur={(e) =>
                    updateDatabase(
                      intr.instructor_id,
                      "staff_memo",
                      e.target.value,
                      intr.instructor_name
                    )
                  }
                >
                  {intr.staff_memo}
                </textarea>
              </li>
            ))}
        </ul>
        <ul className="calc_total_list">
          <li>合計</li>
          {salaryList &&
            salaryList.map((intr) => (
              <li key={intr.instructor_id}>
                {intr.number_fee * intr.number +
                  intr.intr_number_fee * intr.intr_number +
                  intr.pickup_fee * intr.pickup +
                  intr.trainfare_fee * intr.trainfare +
                  intr.shooting_fee * intr.shooting +
                  intr.staff_fee * intr.staff}
                円
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export default SpecialFeePage;
