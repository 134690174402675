import React, { useState, useEffect } from "react";
import "../../../../styles/scss/recital2025MusicEdit.scss";
import { useLoginCheck } from "../../../../hooks/useLoginCheck";

function MusicEditPage() {
  useLoginCheck();
  const [musicEditList, setMusicEditList] = useState([]);
  const [intrGridDatas, setIntrGridDatas] = useState([]);
  const [numberGridDatas, setNumberGridDatas] = useState([]);
  const [listLength, setListLength] = useState(0); // liの数

  // 音編希望一覧の取得
  const fetchMusicEditData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_recital2025_music_edit/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setMusicEditList(data);
      calcGridRows(data);
      console.log(data);
      // liのタグを計算
      const countSongs = (data) => {
        return data.reduce((total, instructor) => {
          const musicEditRequests = instructor.music_edit_requests || [];
          const songCount = musicEditRequests.reduce((sum, request) => {
            const songs = request.songs || [];
            return sum + songs.length;
          }, 0);
          return total + songCount;
        }, 0);
      };
      setListLength(countSongs(data) + 1); // データの数にヘッダーを追加
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // グリッドの計算
  function calcGridRows(data) {
    const intrGridRowsArray = [];
    const numberGridRowsArray = [];
    let gridLineCounter = 2; // グリッド行番号のカウンターを初期化

    data.forEach((element) => {
      const intrStartNumber = gridLineCounter; // インストラクターの開始行番号
      const numberArray = [];

      element.music_edit_requests.forEach((elm) => {
        const numberStartNumber = gridLineCounter; // リクエストの開始行番号
        gridLineCounter += elm.songs.length; // 曲の数だけ行番号を進める
        const numberEndNumber = gridLineCounter; // リクエストの終了行番号
        numberArray.push(`${numberStartNumber} / ${numberEndNumber}`);
      });

      const intrEndNumber = gridLineCounter; // インストラクターの終了行番号
      intrGridRowsArray.push(`${intrStartNumber} / ${intrEndNumber}`);
      numberGridRowsArray.push(numberArray);
    });

    setIntrGridDatas(intrGridRowsArray);
    setNumberGridDatas(numberGridRowsArray);
    console.log(numberGridRowsArray);
  }

  useEffect(() => {
    fetchMusicEditData();
  }, []);

  return (
    <>
      <h1>発表会イントラ音編</h1>
      <div>
        <div
          className="graph"
          style={{ gridTemplateRows: `repeat(${listLength}, auto)` }}
        >
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li style={{ gridRow: `1 / 2` }}>イントラ名</li>
            {musicEditList &&
              musicEditList.map((instructor, index) => {
                return (
                  <li
                    key={instructor.instructor_name}
                    style={{ gridRow: intrGridDatas[index] }}
                  >
                    {instructor.instructor_name}
                  </li>
                );
              })}
          </ul>
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li>ナンバー</li>
            {musicEditList &&
              musicEditList.map((instructor, index) =>
                instructor.music_edit_requests.map(
                  (music_edit_request, idx) => (
                    <li style={{ gridRow: numberGridDatas[index][idx] }}>
                      {music_edit_request.lesson_name}
                    </li>
                  )
                )
              )}
          </ul>
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li>希望</li>
            {musicEditList &&
              musicEditList.map((instructor) =>
                instructor.music_edit_requests.map((music_edit_request) =>
                  music_edit_request.songs.map((song, index) => (
                    <li>{index + 1}</li>
                  ))
                )
              )}
          </ul>
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li>アーティスト名</li>
            {musicEditList &&
              musicEditList.map((instructor) =>
                instructor.music_edit_requests.map((music_edit_request) =>
                  music_edit_request.songs.map((song) => (
                    <li>{song.artist_name}</li>
                  ))
                )
              )}
          </ul>
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li>曲名</li>
            {musicEditList &&
              musicEditList.map((instructor) =>
                instructor.music_edit_requests.map((music_edit_request) =>
                  music_edit_request.songs.map((song) => <li>{song.title}</li>)
                )
              )}
          </ul>
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li>時間</li>
            {musicEditList &&
              musicEditList.map((instructor) =>
                instructor.music_edit_requests.map((music_edit_request) =>
                  music_edit_request.songs.map((song) => (
                    <li>
                      {song.start_min}:{song.start_sec}〜{song.end_min}:
                      {song.end_sec}
                    </li>
                  ))
                )
              )}
          </ul>
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li>説明</li>
            {musicEditList &&
              musicEditList.map((instructor) =>
                instructor.music_edit_requests.map((music_edit_request) =>
                  music_edit_request.songs.map((song) => <li>{song.memo}</li>)
                )
              )}
          </ul>
          <ul style={{ gridRow: `span ${listLength}` }}>
            <li>URL</li>
            {musicEditList &&
              musicEditList.map((instructor) =>
                instructor.music_edit_requests.map((music_edit_request) =>
                  music_edit_request.songs.map((song) => (
                    <li>
                      <a
                        href={song.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {song.url}
                      </a>
                    </li>
                  ))
                )
              )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default MusicEditPage;
