import React, { useState, useEffect, useRef } from "react";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import "../../styles/scss/orderPrintList.scss";

function OrderPrintListPage() {
  useLoginCheck();
  const [orders, setOrders] = useState([]); // オーダー

  // 注文一覧の取得
  const fetchOrdersData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_orders/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();

      console.log(data);

      setOrders(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 初期表示時に注文のリストを取得
  useEffect(() => {
    fetchOrdersData();
  }, []);

  return (
    <div id="a4">
      {/* reset.cssを直接記述 */}
      <style>
        {`
          /* http://meyerweb.com/eric/tools/css/reset/
          v2.0 | 20110126
          License: none (public domain)
          */
          html,
          body,
          div,
          span,
          applet,
          object,
          iframe,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          blockquote,
          pre,
          a,
          abbr,
          acronym,
          address,
          big,
          cite,
          code,
          del,
          dfn,
          em,
          img,
          ins,
          kbd,
          q,
          s,
          samp,
          small,
          strike,
          strong,
          sub,
          sup,
          tt,
          var,
          b,
          u,
          i,
          center,
          dl,
          dt,
          dd,
          ol,
          ul,
          li,
          fieldset,
          form,
          label,
          legend,
          table,
          caption,
          tbody,
          tfoot,
          thead,
          tr,
          th,
          td,
          article,
          aside,
          canvas,
          details,
          embed,
          figure,
          figcaption,
          footer,
          header,
          hgroup,
          menu,
          nav,
          output,
          ruby,
          section,
          summary,
          time,
          mark,
          audio,
          video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
          }
          /* HTML5 display-role reset for older browsers */
          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section {
            display: block;
          }
          body {
            line-height: 1;
          }
          ol,
          ul {
            list-style: none;
          }
          blockquote,
          q {
            quotes: none;
          }
          blockquote:before,
          blockquote:after,
          q:before,
          q:after {
            content: "";
            content: none;
          }
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }
          li {
            list-style: none;
          }

          input {
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            border-radius: 0;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }

        `}
      </style>

      {orders.map((order, index) => (
        <ul
          key={index}
          className={`list-grid container ${order.shipped_flg ? "done" : ""}`}
        >
          <li className="cell">{order.id}</li>
          <li className="cell">{order.customer_id}</li>
          <li className="cell">{order.name}</li>
          <li className="cell">{order.postcode}</li>
          <li className="cell">{order.address}</li>
          <li className="cell">{order.tel}</li>
          <li className="cell">{order.mail}</li>
          <li className="cell">
            {order.items.map((item) => item.product_name).join("\n")}
          </li>
          <li className="cell">¥{order.total_amount.toLocaleString()}</li>
          <li className="cell tracking">
            {order.tracking_number === null && "未登録"}
          </li>
          <li className="cell">
            <input type="checkbox" checked={order.shipped} readOnly></input>
          </li>
        </ul>
      ))}
    </div>
  );
}

export default OrderPrintListPage;
