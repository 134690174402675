import React, { useState, useEffect } from "react";
import "../../styles/scss/salary.scss";
import { useLoginCheck } from "../../hooks/useLoginCheck";

function SalaryPage() {
  useLoginCheck();
  const [salaryList, setSalaryList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // 音編希望一覧の取得
  const fetchSalaryListData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_salary/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selected_year: selectedYear,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setSalaryList(data);
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    fetchSalaryListData();
  }, [selectedYear]);

  // 数値の3桁カンマ区切り
  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  return (
    <>
      <h1>給与一覧</h1>
      <select
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
        style={{ marginBottom: "20px", padding: "10px", borderRadius: "5px" }}
      >
        {Array.from({ length: new Date().getFullYear() - 2023 + 1 }, (_, i) => (
          <option key={i} value={2023 + i}>
            {2023 + i}
          </option>
        ))}
      </select>
      <div
        className="graph"
        style={{
          gridTemplateRows: `repeat(${salaryList.length + 2}, auto)`,
        }}
      >
        {/* 名前 */}
        <ul style={{ gridRow: `span ${salaryList.length + 2}` }}>
          <li className="blue" style={{ gridRow: "span 2" }}>
            名前
          </li>
          {salaryList &&
            salaryList.map((salary, index) => {
              return (
                <li key={`name-${index}`}>
                  {salary.first_name
                    ? `${salary.first_name} ${salary.last_name}`
                    : salary.username}
                </li>
              );
            })}
        </ul>
        {Array.from({ length: 12 }, (_, i) => {
          const monthIndex = 12 - i;
          return (
            <>
              {/* 給料 */}
              <ul
                style={{
                  gridRow: `span ${salaryList.length + 2}`,
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <li className="blue" style={{ gridColumn: "span 2" }}>
                  {monthIndex}月
                </li>
                <li className="blue">給料</li>
                <li className="blue">交通費</li>
                {salaryList &&
                  salaryList.map((salary, index) => {
                    return (
                      <>
                        <li
                          key={`month${monthIndex}-total-compensation-${index}`}
                        >
                          {salary.monthly_data[String(monthIndex)] &&
                            `¥${formatNumber(
                              salary.monthly_data[String(monthIndex)]
                                .total_compensation
                            )}`}
                        </li>
                        <li key={`month${monthIndex}-total-trainfare-${index}`}>
                          {salary.monthly_data[String(monthIndex)] &&
                            `¥${formatNumber(
                              salary.monthly_data[String(monthIndex)]
                                .total_trainfare
                            )}`}
                        </li>
                      </>
                    );
                  })}
              </ul>
            </>
          );
        })}
      </div>
    </>
  );
}

export default SalaryPage;
