import React, { useContext } from "react";
import { useLoading } from "../contexts/LoadingContext";

function LoadingSpinner() {
  const { isLoading } = useLoading();

  if (!isLoading) {
    return null;
  }

  return (
    <div className="loading-overlay">
      <div className="loader">読み込み中...</div>
    </div>
  );
}

export default LoadingSpinner;
